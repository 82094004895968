import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class WsItaService {
  private url = 'https://api.yariel.dev/';

  constructor(private _http: HttpClient) {}

  public login(login: any) {
    let json = JSON.stringify(login);
    let header = new HttpHeaders().set(
      'Content-Type',
      'application/json;  charset=utf-8'
    );
    return this._http.post(this.url + 'login/', json, { headers: header });
  }

  public getMessages() {
    return this._http.get(this.url + 'messages/');
  }

  public getMessage(id: any) {
    return this._http.get(this.url + 'messages/' + id);
  }

  public postMessage(message: any) {
    let json = JSON.stringify(message);
    let header = new HttpHeaders().set(
      'Content-Type',
      'application/json;  charset=utf-8'
    );
    return this._http.post(this.url + 'messages/', json, { headers: header });
  }

  public putMessage(message: any) {
    let json = JSON.stringify(message);
    let header = new HttpHeaders().set(
      'Content-Type',
      'application/json;  charset=utf-8'
    );
    return this._http.put(this.url + 'messages/' + message.int_id, json, {
      headers: header,
    });
  }

  public deleteMessage(id: any) {
    let header = new HttpHeaders().set(
      'Content-Type',
      'application/json;  charset=utf-8'
    );
    console.log(this.url + 'messages/' + id);
    return this._http.delete(this.url + 'messages/' + id, { headers: header });
  }

  public getNews() {
    return this._http.get(this.url + 'news/');
  }

  public getNew(id: any) {
    return this._http.get(this.url + 'news/' + id);
  }

  public postNews(newsData: any) {
    let json = JSON.stringify(newsData);
    let header = new HttpHeaders().set(
      'Content-Type',
      'application/json;  charset=utf-8'
    );
    return this._http.post(this.url + 'news/', json, { headers: header });
  }

  public putNews(newsData: any) {
    let json = JSON.stringify(newsData);
    let header = new HttpHeaders().set(
      'Content-Type',
      'application/json;  charset=utf-8'
    );
    return this._http.put(this.url + 'news/' + newsData.id, json, {
      headers: header,
    });
  }

  public deleteNews(id: any) {
    let header = new HttpHeaders().set(
      'Content-Type',
      'application/json;  charset=utf-8'
    );
    return this._http.delete(this.url + 'news/' + id, { headers: header });
  }
}
