import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SharedServiceService {
  public currentUser: any;

  text =
    '<p style=\'margin-top:12.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:115%;font-family:"Times New Roman",serif;\'>El aspirante a la carrera de Tecnolog&iacute;a Superior en Gesti&oacute;n Ambiental, debe ser una persona con aptitudes y actitudes para:</span></p>\n' +
    '<p style=\'margin-top:12.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:115%;font-family:"Times New Roman",serif;\'>- Aprender y adaptarse a las exigencias de formaci&oacute;n acad&eacute;mica y t&eacute;cnica de la carrera</span></p>\n' +
    '<p style=\'margin-top:12.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:115%;font-family:"Times New Roman",serif;\'>- Ser una persona con la suficiente madurez para comprometerse en concluir con todos sus ciclos de estudio.</span></p>\n' +
    '<p style=\'margin-top:12.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:115%;font-family:"Times New Roman",serif;\'>- Tener sentido de responsabilidad y honradez para poder hacer frente a los desaf&iacute;os en la resoluci&oacute;n de problemas.</span></p>\n' +
    '<p style=\'margin-top:12.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:115%;font-family:"Times New Roman",serif;\'>- Adaptarse e integrarse a los trabajos en grupo, ya que el esfuerzo colectivo y el interdisciplinario ser&aacute; un factor clave para lograr los resultados deseados</span></p>\n' +
    '<p style=\'margin-top:12.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:115%;font-family:"Times New Roman",serif;\'>- Para recibir, entender y comprender los conocimientos generales, espec&iacute;ficos y t&eacute;cnicos que les facultan para seguir la carrera.</span></p>\n' +
    '<p style=\'margin-top:12.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:115%;font-family:"Times New Roman",serif;\'>- Para evolucionar en valores hacia el compromiso social, respeto al medio ambiente, social y cultural de su entorno.</span></p>\n' +
    '<p style=\'margin-top:12.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:115%;font-family:"Times New Roman",serif;\'>- Investigar, procesar, analizar y sintetizar informaci&oacute;n procedente de fuentes diversas y adaptarse a la preparaci&oacute;n por medio de plataformas virtuales de ense&ntilde;anza global y a la actualizaci&oacute;n permanente de conocimientos.</span></p>\n' +
    '<p style=\'margin-top:12.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:115%;font-family:"Times New Roman",serif;\'>- Involucrarse en las practicas del buen vivir, aportando con sentido emprendedor para mejorar la matriz productiva y energ&eacute;tica.</span></p>\n' +
    '<p style=\'margin-top:12.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><u><span style=\'font-size:16px;line-height:115%;font-family:"Times New Roman",serif;\'>Aspecto acad&eacute;mico:</span></u></p>\n' +
    '<p style=\'margin-top:12.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:115%;font-family:"Times New Roman",serif;\'>- Desempe&ntilde;o acad&eacute;mico elevado dentro de la formaci&oacute;n secundaria. [Actitudinal]</span></p>\n' +
    '<p style=\'margin-top:12.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:115%;font-family:"Times New Roman",serif;\'>- Madurez, Inteligencia Emocional, integridad senso-perceptiva, aspecto cognitivo [Aptitudinal]</span></p>\n' +
    '<p style=\'margin-top:12.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:115%;font-family:"Times New Roman",serif;\'>- Conciencia cr&iacute;tica, s&iacute;ntesis, an&aacute;lisis y l&oacute;gica para la resoluci&oacute;n de problemas</span></p>\n' +
    '<p style=\'margin-top:12.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:115%;font-family:"Times New Roman",serif;\'>- Capacidad de reflexi&oacute;n, habilidades para la comprensi&oacute;n de la lectura</span></p>\n' +
    '<p style=\'margin-top:12.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:115%;font-family:"Times New Roman",serif;\'>- Razonamiento abstracto y mec&aacute;nico, manejo de la inferencia y de la analog&iacute;a.</span></p>\n' +
    '<p style=\'margin-top:12.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:115%;font-family:"Times New Roman",serif;\'>- Realizar un examen te&oacute;rico y actitudinal como psicol&oacute;gico.</span></p>\n' +
    '<p style=\'margin-top:12.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><u><span style=\'font-size:16px;line-height:115%;font-family:"Times New Roman",serif;\'>Aspecto Humano:</span></u></p>\n' +
    '<p style=\'margin-top:12.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:115%;font-family:"Times New Roman",serif;\'>- Valores &eacute;ticos y morales inspirados en visi&oacute;n, sensibilidad social y actitud solidaria, compromiso social para lograr un bienestar com&uacute;n, respeto por la vida humana, desde su concepci&oacute;n hasta la muerte, disposici&oacute;n al servicio, particularmente a los m&aacute;s desprotegidos.</span></p>\n' +
    '<p style=\'margin-top:12.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:115%;font-family:"Times New Roman",serif;\'>- Inter&eacute;s por la educaci&oacute;n, protecci&oacute;n y administraci&oacute;n empresarial y financiera.</span></p>\n' +
    '<p style=\'margin-top:12.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:115%;font-family:"Times New Roman",serif;\'>- Equilibrio emocional.</span></p>\n' +
    '<p style=\'margin-top:12.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:115%;font-family:"Times New Roman",serif;\'>- Capacidad para interrelacionarse con las personas y comunidades de su contexto laboral.</span></p>';

  public courses = [
    {
      id: '1',
      name: 'TECNOLOGÍA SUPERIOR EN GESTIÓN AMBIENTAL',
      time: 'Duración: 5 periodos (2 años y medio)',
      title1: 'Perfil de ingreso',
      body1: this.text,
      title2: 'Perfil de egreso',
      body2:
        '<p style=\'margin-top:12.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:115%;font-family:"Times New Roman",serif;\'>El profesional Tecn&oacute;logo Superior en Gesti&oacute;n Ambiental, tendr&aacute; una s&oacute;lida formaci&oacute;n te&oacute;rico-pr&aacute;ctica, con mentalidad emprendedora con s&oacute;lidos conocimientos en:</span></p>\n' +
        '<p style=\'margin-top:12.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:115%;font-family:"Times New Roman",serif;\'>Manejo de residuos s&oacute;lidos urbanos y residuos peligrosos, evaluaci&oacute;n de impactos del agua, suelo, y aire, legislaci&oacute;n y seguridad industrial as&iacute; como estudios de impactos ambientales, auditor&iacute;a y gesti&oacute;n ambiental, procesos industriales y producci&oacute;n m&aacute;s limpia, manejo y conservaci&oacute;n de recursos naturales renovables y no renovables, manejo de sistemas de informaci&oacute;n geogr&aacute;ficos y de recursos tecnol&oacute;gicos, manejo, aprovechamiento y desarrollo de tecnolog&iacute;as alternativas, proyectos con el fin de preservar, proteger, recuperar y mejorar los procesos ambientales en beneficio de las personas, la sociedad y el medio ambiente.</span></p>\n' +
        '<p style=\'margin-top:12.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:115%;font-family:"Times New Roman",serif;\'>El Tecn&oacute;logo Superior en Gesti&oacute;n Ambiental tendr&aacute; habilidades para integrar, proponer e implementar alternativas de remediaci&oacute;n ambiental de los recursos agua, aire y suelo y en el tratamiento, reciclaje, disposici&oacute;n, traslado y manejo de desechos s&oacute;lidos urbanos y peligrosos.</span></p>',
      title3: 'Perfil profesional',
      body3:
        '<p style=\'margin-top:12.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:115%;font-family:"Times New Roman",serif;\'>El perfil profesional para el Tecn&oacute;logo Superior en Gesti&oacute;n Ambiental:</span></p>\n' +
        '<ul style="list-style-type: disc;margin-left:0in;">\n' +
        '    <li><span style=\'line-height:115%;font-family:"Times New Roman",serif;font-size:16px;\'>Aplicara t&eacute;cnicas y procedimientos en la gesti&oacute;n de desechos de productos o materias primas, elaboraci&oacute;n de planes de manejo ambiental para descargas s&oacute;lidas o l&iacute;quidas, identificaci&oacute;n de potencialidades de reciclaje y reutilizaci&oacute;n de contaminantes, entre los principales.</span></li>\n' +
        '    <li><span style=\'line-height:115%;font-family:"Times New Roman",serif;font-size:16px;\'>Promover&aacute; el desarrollo sustentable, minimiza el impacto ambiental, dentro del principio de responsabilidad intergeneracional en todos los sistemas de producci&oacute;n que de manera directa o indirecta afectan al ambiente.&nbsp;</span></li>\n' +
        '    <li><span style=\'line-height:115%;font-family:"Times New Roman",serif;font-size:16px;\'>Generar&aacute; consciencia en administradores de industrias, productores y agricultores para que realicen sus actividades minimizando los impactos ambientales, reduciendo la contaminaci&oacute;n y el desperdicio de recursos.&nbsp;</span></li>\n' +
        '</ul>\n' +
        '<p><span style=\'font-size:16px;line-height:107%;font-family:"Times New Roman",serif;\'>Impulsar&aacute; el uso de energ&iacute;as no tradicionales a trav&eacute;s del uso de energ&iacute;as alternativas para la industria.</span></p>',
      student: '20',
      ratings: '5',
      image: 'assets/image/carrera1.JPG',
      pdf: 'assets/pdf/pdf1.pdf',
    },
    {
      id: '2',
      name: 'TECNOLOGÍA SUPERIOR EN TURISMO',
      time: 'Duración: 5 periodos (2 años y medio)',
      title1: 'Perfil de ingreso',
      body1: this.text,
      title2: 'Perfil de egreso',
      body2:
        '<p style=\'margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;\'><span style=\'font-size:16px;font-family:"Times New Roman",serif;color:black;\'>El Tecn&oacute;logo Superior en Turismo tiene una s&oacute;lida formaci&oacute;n cultural para desempe&ntilde;arse con&nbsp;gran capacidad de juicio y liderazgo en el &aacute;mbito profesional, dentro de su entorno sociocultural y en sus relaciones con el medio natural.</span></p>\n' +
        '<p style=\'margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;\'><span style=\'font-size:16px;font-family:"Times New Roman",serif;color:black;\'>Est&aacute; capacitado para la investigaci&oacute;n, el&nbsp;an&aacute;lisis y la interpretaci&oacute;n del fen&oacute;meno tur&iacute;stico, asoci&aacute;ndolo con su entorno con bases&nbsp;l&oacute;gicas, filos&oacute;ficas, hist&oacute;ricas, sociol&oacute;gicas y econ&oacute;micas.</span></p>\n' +
        '<p style=\'margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;\'><span style=\'font-size:16px;font-family:"Times New Roman",serif;color:black;\'>Su habilidad de comunicaci&oacute;n es&nbsp;&oacute;ptima expres&aacute;ndose en espa&ntilde;ol y por lo menos un idioma extranjero.</span></p>\n' +
        '<p style=\'margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;\'><span style=\'font-size:16px;font-family:"Times New Roman",serif;color:black;\'>Tiene m&uacute;ltiples motivaciones que lo inducen hacia la b&uacute;squeda de oportunidades para el desarrollo tur&iacute;stico y hotelero hacia la soluci&oacute;n de problemas que faciliten el aprendizaje y la realizaci&oacute;n de trabajos creativos en equipo.</span></p>',
      title3: 'Perfil profesional',
      body3:
        '<p style=\'margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;\'><span style=\'font-size:16px;font-family:"Times New Roman",serif;color:black;\'>El Tecn&oacute;logo Superior en Turismo es un profesional que podr&aacute;:</span></p>\n' +
        '<ul style="margin-bottom:0in;margin-top:0in;" type="disc">\n' +
        '    <li style=\'margin-top:0in;margin-right:0in;margin-bottom:     0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;color:black;text-align:justify;background:white;\'><span style=\'font-size:16px;font-family:"Times New Roman",serif;\'>Promover el desarrollo del Turismo Sostenible en la zona en la que desarrolle su actividad profesional, de acuerdo con las pol&iacute;ticas y planes de desarrollo local.</span></li>\n' +
        '    <li style=\'margin-top:0in;margin-right:0in;margin-bottom:     0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;color:black;text-align:justify;background:white;\'><span style=\'font-size:16px;font-family:"Times New Roman",serif;\'>Elaborar proyectos de empresas privadas o de vinculaci&oacute;n con la comunidad en las &aacute;reas de servicios tur&iacute;sticos considerando los impactos ambientales, sociales, econ&oacute;micos y culturales.</span></li>\n' +
        '    <li style=\'margin-top:0in;margin-right:0in;margin-bottom:     0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;color:black;text-align:justify;background:white;\'><span style=\'font-size:16px;font-family:"Times New Roman",serif;\'>Planificar diferentes operaciones concernientes al &aacute;rea tur&iacute;stica con estricto apego a un&nbsp;marco &eacute;tico y legal que lo lleva a comprometerse en la integralidad y calidad de los&nbsp;servicios ofertados al cliente, turista o visitante.</span></li>\n' +
        '    <li style=\'margin-top:0in;margin-right:0in;margin-bottom:     0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;color:black;text-align:justify;background:white;\'><span style=\'font-size:16px;font-family:"Times New Roman",serif;\'>Valorar la diversidad de la flora, fauna y la multiculturalidad patrimonial a nivel mundial&nbsp;y nacional para el desarrollo de actividades tur&iacute;sticas sostenibles, considerando actores,&nbsp;elementos claves, metodolog&iacute;a y t&eacute;cnicas adecuadas.<br>&nbsp;Aplicar el conocimiento fundamental de ciencias b&aacute;sicas y sociales para sustentar el desarrollo de procesos encaminados a la administraci&oacute;n y gesti&oacute;n de actividades tur&iacute;sticas.</span></li>\n' +
        '    <li style=\'margin-top:0in;margin-right:0in;margin-bottom:     0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;color:black;text-align:justify;background:white;\'><span style=\'font-size:16px;font-family:"Times New Roman",serif;\'>Aplicar t&eacute;cnicas de expresi&oacute;n oral y escrita para una comunicaci&oacute;n &oacute;ptima y eficaz.</span></li>\n' +
        '    <li style=\'margin-top:0in;margin-right:0in;margin-bottom:     0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;color:black;text-align:justify;background:white;\'><span style=\'font-size:16px;font-family:"Times New Roman",serif;\'>Utilizar las tecnolog&iacute;as de la informaci&oacute;n y las comunicaciones para su actualizaci&oacute;n&nbsp;profesional y aplicaci&oacute;n en el &aacute;mbito laboral conforme al desarrollo de la actividad&nbsp;tur&iacute;stica.</span></li>\n' +
        '    <li style=\'margin-top:0in;margin-right:0in;margin-bottom:     0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;color:black;text-align:justify;background:white;\'><span style=\'font-size:16px;font-family:"Times New Roman",serif;\'>Desarrollar habilidades para la aplicaci&oacute;n del ingl&eacute;s u otro idioma extranjero como&nbsp;herramienta de comunicaci&oacute;n comercial en la gesti&oacute;n empresarial en el &aacute;mbito&nbsp;tur&iacute;stico.</span></li>\n' +
        '    <li style=\'margin-top:0in;margin-right:0in;margin-bottom:     0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;color:black;text-align:justify;background:white;\'><span style=\'font-size:16px;font-family:"Times New Roman",serif;\'>Investigar situaciones que se dan en los diferentes espacios y destinos tur&iacute;sticos para generar proyectos o productos sostenibles como alternativas de soluci&oacute;n.</span><span style=\'font-size:16px;font-family:"Times New Roman",serif;color:black;\'>El Tecn&oacute;logo Superior en Turismo es un profesional que podr&aacute;:</span>\n' +
        '        <ul style="margin-bottom:0in;margin-top:0in;" type="disc">\n' +
        '            <li style=\'margin-top:0in;margin-right:0in;margin-bottom:     0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;color:black;text-align:justify;background:white;\'><span style=\'font-size:16px;font-family:"Times New Roman",serif;\'>Promover el desarrollo del Turismo Sostenible en la zona en la que desarrolle su actividad profesional, de acuerdo con las pol&iacute;ticas y planes de desarrollo local.</span></li>\n' +
        '            <li style=\'margin-top:0in;margin-right:0in;margin-bottom:     0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;color:black;text-align:justify;background:white;\'><span style=\'font-size:16px;font-family:"Times New Roman",serif;\'>Elaborar proyectos de empresas privadas o de vinculaci&oacute;n con la comunidad en las &aacute;reas de servicios tur&iacute;sticos considerando los impactos ambientales, sociales, econ&oacute;micos y culturales.</span></li>\n' +
        '            <li style=\'margin-top:0in;margin-right:0in;margin-bottom:     0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;color:black;text-align:justify;background:white;\'><span style=\'font-size:16px;font-family:"Times New Roman",serif;\'>Planificar diferentes operaciones concernientes al &aacute;rea tur&iacute;stica con estricto apego a un&nbsp;marco &eacute;tico y legal que lo lleva a comprometerse en la integralidad y calidad de los&nbsp;servicios ofertados al cliente, turista o visitante.</span></li>\n' +
        '            <li style=\'margin-top:0in;margin-right:0in;margin-bottom:     0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;color:black;text-align:justify;background:white;\'><span style=\'font-size:16px;font-family:"Times New Roman",serif;\'>Valorar la diversidad de la flora, fauna y la multiculturalidad patrimonial a nivel mundial&nbsp;y nacional para el desarrollo de actividades tur&iacute;sticas sostenibles, considerando actores,&nbsp;elementos claves, metodolog&iacute;a y t&eacute;cnicas adecuadas.<br>&nbsp;Aplicar el conocimiento fundamental de ciencias b&aacute;sicas y sociales para sustentar el desarrollo de procesos encaminados a la administraci&oacute;n y gesti&oacute;n de actividades tur&iacute;sticas.</span></li>\n' +
        '            <li style=\'margin-top:0in;margin-right:0in;margin-bottom:     0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;color:black;text-align:justify;background:white;\'><span style=\'font-size:16px;font-family:"Times New Roman",serif;\'>Aplicar t&eacute;cnicas de expresi&oacute;n oral y escrita para una comunicaci&oacute;n &oacute;ptima y eficaz.</span></li>\n' +
        '            <li style=\'margin-top:0in;margin-right:0in;margin-bottom:     0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;color:black;text-align:justify;background:white;\'><span style=\'font-size:16px;font-family:"Times New Roman",serif;\'>Utilizar las tecnolog&iacute;as de la informaci&oacute;n y las comunicaciones para su actualizaci&oacute;n&nbsp;profesional y aplicaci&oacute;n en el &aacute;mbito laboral conforme al desarrollo de la actividad&nbsp;tur&iacute;stica.</span></li>\n' +
        '            <li style=\'margin-top:0in;margin-right:0in;margin-bottom:     0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;color:black;text-align:justify;background:white;\'><span style=\'font-size:16px;font-family:"Times New Roman",serif;\'>Desarrollar habilidades para la aplicaci&oacute;n del ingl&eacute;s u otro idioma extranjero como&nbsp;herramienta de comunicaci&oacute;n comercial en la gesti&oacute;n empresarial en el &aacute;mbito&nbsp;tur&iacute;stico.</span></li>\n' +
        '            <li style=\'margin-top:0in;margin-right:0in;margin-bottom:     0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;color:black;text-align:justify;background:white;\'><span style=\'font-size:16px;font-family:"Times New Roman",serif;\'>Investigar situaciones que se dan en los diferentes espacios y destinos tur&iacute;sticos para generar proyectos o productos sostenibles como alternativas de soluci&oacute;n.</span></li>\n' +
        '        </ul>\n' +
        '    </li>\n' +
        '</ul>',
      student: '20',
      ratings: '5',
      image: 'assets/image/carrera2.jpg',
      pdf: 'assets/pdf/pdf2.pdf',
    },
    {
      id: '3',
      name: 'TECNOLOGÍA SUPERIOR EN PRODUCCIÓN AGROPECUARIA',
      time: 'Duración: 5 periodos (2 años y medio)',
      title1: 'Perfil de ingreso',
      body1: this.text,
      title2: 'Perfil de egreso',
      body2:
        '<p style=\'margin-top:12.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:115%;font-family:"Times New Roman",serif;\'>El Tecn&oacute;logo Superior en Producci&oacute;n Agropecuaria est&aacute; capacitado para desempe&ntilde;arse en empresas dedicadas a la producci&oacute;n agropecuaria en las &aacute;reas de producci&oacute;n, comercializaci&oacute;n, manejo de personal, asesor&iacute;a y procesos agroindustriales, podr&aacute;, adem&aacute;s, formar parte de instituciones oficiales del sector agropecuario a nivel municipal y/o gubernamental o desempe&ntilde;arse en consultoras agropecuarias que brindan asesor&iacute;as t&eacute;cnicas y de fomento a productores. Otro campo en que puede desenvolverse profesionalmente es en empresas dedicadas a la comercializaci&oacute;n de productos, maquinarias y herramientas agropecuarias y/o tambi&eacute;n ejerciendo su profesi&oacute;n de manera independiente.<br>&nbsp;<br>&nbsp;El egresado de la carrera de Tecnolog&iacute;a Superior en Producci&oacute;n Agropecuaria del Instituto Superior Tecnol&oacute;gico Andino:</span></p>\n' +
        '<ul style="list-style-type: disc;margin-left:0in;">\n' +
        '    <li><span style=\'line-height:115%;font-family:"Times New Roman",serif;font-size:16px;\'>Implementa las diferentes actividades de la cadena productiva, de acuerdo a los requerimientos de la empresa agropecuaria, utilizando los conocimientos adquiridos en soluciones innovadoras y pertinentes a los problemas productivos propios del campo ocupacional, cumpliendo la normativa legal vigente y las certificaciones de calidad.</span></li>\n' +
        '    <li><span style=\'line-height:115%;font-family:"Times New Roman",serif;font-size:16px;\'>Transfiere tecnolog&iacute;as a la agricultura familiar campesina y al peque&ntilde;o productor asesor&aacute;ndolos en aspectos t&eacute;cnicos y de fomento con el fin de optimizar la eficiencia de la producci&oacute;n, a trav&eacute;s de reuniones y elaboraci&oacute;n de informes de mediana complejidad, cumpliendo la normativa legal vigente y las certificaciones de calidad cuando corresponda.</span></li>\n' +
        '    <li><span style=\'line-height:115%;font-family:"Times New Roman",serif;font-size:16px;\'>Implementa alternativas productivas de industrializaci&oacute;n de materias primas de la explotaci&oacute;n agropecuaria, demostrando responsabilidad y respeto en el cumplimiento de los est&aacute;ndares de calidad, normativas vigentes y los plazos establecidos.</span></li>\n' +
        '    <li><span style=\'line-height:115%;font-family:"Times New Roman",serif;font-size:16px;\'>Supervisa el personal involucrado en los procesos productivos, de manera colaborativa, reconociendo las caracter&iacute;sticas de la empresa agropecuaria, en funci&oacute;n de alcanzar los objetivos comunes y el cumplimiento de las normativas vigentes.</span></li>\n' +
        '    <li><span style=\'line-height:115%;font-family:"Times New Roman",serif;font-size:16px;\'>Emplea t&eacute;cnicas de promoci&oacute;n y venta de productos y servicios agropecuarios, integrando la tecnolog&iacute;a de la informaci&oacute;n y la comunicaci&oacute;n, para dar soluciones a las necesidades de los clientes, cumpliendo la normativa legal vigente y las certificaciones de calidad.</span></li>\n' +
        '</ul>',
      title3: 'Perfil profesional',
      body3:
        '<p style=\'margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;\'><span style=\'font-size:16px;font-family:"Times New Roman",serif;color:black;\'>El Tecn&oacute;logo Superior en Turismo es un profesional que podr&aacute;:</span></p>\n' +
        '<ul style="list-style-type: disc;margin-left:0in;">\n' +
        '    <li><span style=\'line-height:115%;font-family:"Times New Roman",serif;font-size:16px;\'>Planificaci&oacute;n, orientaci&oacute;n, ejecuci&oacute;n y evaluaci&oacute;n del proyecto productivo de la explotaci&oacute;n, ponderando las ventajas y desventajas de alternativas de producci&oacute;n no tradicionales, evaluando y decidiendo sobre las tecnolog&iacute;as m&aacute;s apropiadas a utilizar en funci&oacute;n de criterios tanto econ&oacute;mico</span></li>\n' +
        '    <li><span style=\'line-height:115%;font-family:"Times New Roman",serif;font-size:16px;\'>Productivos como ecol&oacute;gicos y socioculturales, y la forma m&aacute;s conveniente de organizaci&oacute;n del trabajo, considerando el impacto de las pol&iacute;ticas p&uacute;blicas generales y espec&iacute;ficas sobre la actividad productiva de que se trate;</span></li>\n' +
        '    <li><span style=\'line-height:115%;font-family:"Times New Roman",serif;font-size:16px;\'>Determinaci&oacute;n de los requerimientos y posibilidades de obras de infraestructura e instalaciones, m&aacute;quinas, equipos y herramientas para la explotaci&oacute;n y la planificaci&oacute;n de su uso;</span></li>\n' +
        '    <li><span style=\'line-height:115%;font-family:"Times New Roman",serif;font-size:16px;\'>Adquisici&oacute;n y almacenamiento de insumos y bienes de capital; supervisi&oacute;n y registro de los procesos productivos y de los servicios;&nbsp;</span></li>\n' +
        '    <li><span style=\'line-height:115%;font-family:"Times New Roman",serif;font-size:16px;\'>Aplicaci&oacute;n de la legislaci&oacute;n contable, fiscal, laboral, agraria, de seguridad e higiene del trabajo y de protecci&oacute;n del medio ambiente;&nbsp;</span></li>\n' +
        '    <li><span style=\'line-height:115%;font-family:"Times New Roman",serif;font-size:16px;\'>Comercializaci&oacute;n de los productos, evaluaci&oacute;n de las oportunidades de mercado, elaboraci&oacute;n de las respectivas estrategias y negociaci&oacute;n de intereses con otros agentes, interviniendo eventualmente, en grupos productivos, corporativos o comunitarios; informaci&oacute;n y actualizaci&oacute;n de conocimientos sobre la evoluci&oacute;n tecnol&oacute;gica y econ&oacute;mica del sector agropecuario.</span></li>\n' +
        '    <li><span style=\'line-height:115%;font-family:"Times New Roman",serif;font-size:16px;\'>Preparar el suelo previo a la siembra o plantaci&oacute;n de especies vegetales;</span></li>\n' +
        '    <li><span style=\'line-height:115%;font-family:"Times New Roman",serif;font-size:16px;\'>Realizar la siembra o implantaci&oacute;n de las distintas especies vegetales;</span></li>\n' +
        '    <li><span style=\'line-height:115%;font-family:"Times New Roman",serif;font-size:16px;\'>Operar diferentes sistemas de riego y drenaje;</span></li>\n' +
        '    <li><span style=\'line-height:115%;font-family:"Times New Roman",serif;font-size:16px;\'>Preparar y manejar alm&aacute;cigos;</span></li>\n' +
        '    <li><span style=\'line-height:115%;font-family:"Times New Roman",serif;font-size:16px;\'>Instalar y manejar viveros;</span></li>\n' +
        '    <li><span style=\'line-height:115%;font-family:"Times New Roman",serif;font-size:16px;\'>Cuidar y proteger los cultivos;</span></li>\n' +
        '    <li><span style=\'line-height:115%;font-family:"Times New Roman",serif;font-size:16px;\'>Cosechar, acondicionar (seleccionar, empacar, conservar, etc.), almacenar y transportar los productos obtenidos, evaluando la calidad de los mismos de acuerdo a los requerimientos del mercado y tratando, en todos los casos, de incorporar etapas hacia arriba de la cadena productiva y comercial, incluyendo el procesamiento primario, que permitan agregarles valor.</span></li>\n' +
        '    <li><span style=\'line-height:115%;font-family:"Times New Roman",serif;font-size:16px;\'>Aplicar las diferentes t&eacute;cnicas de manejo de distintas especies animales, seg&uacute;n categor&iacute;as, fase productiva, caracter&iacute;sticas raciales y tipo de explotaci&oacute;n;</span></li>\n' +
        '    <li><span style=\'line-height:115%;font-family:"Times New Roman",serif;font-size:16px;\'>Aplicar el plan sanitario de la explotaci&oacute;n y las medidas de prevenci&oacute;n necesarias para mantener el buen estado de salud de los animales y personas;</span></li>\n' +
        '    <li><span style=\'line-height:115%;font-family:"Times New Roman",serif;font-size:16px;\'>Producir y suministrar alimentos voluminosos y concentrados, aplicando la estrategia de alimentaci&oacute;n adecuada al tipo de explotaci&oacute;n y fase productiva de los animales;</span></li>\n' +
        '    <li><span style=\'line-height:115%;font-family:"Times New Roman",serif;font-size:16px;\'>Realizar las tareas necesarias para el acondicionamiento, comercializaci&oacute;n y transporte de los productos obtenidos, evaluando la calidad de los mismos de acuerdo a los requerimientos del mercado y tratando en todos los casos, de incorporar etapas hacia arriba de la cadena productiva y comercial, incluyendo el procesamiento primario, que permitan agregarles valor.</span></li>\n' +
        '</ul>',
      student: '20',
      ratings: '5',
      image: 'assets/image/carrera3.JPG',
      pdf: 'assets/pdf/pdf3.pdf',
    },
    {
      id: '4',
      name: 'TECNOLOGÍA SUPERIOR EN GESTION DEL TALENTO HUMANO',
      time: 'Duración: 5 periodos (2 años y medio)',
      title1: 'Perfil de ingreso',
      body1: this.text,
      title2: 'Perfil de egreso',
      body2:
        '<p style=\'margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;\'><span style=\'font-size:16px;font-family:"Times New Roman",serif;color:black;\'>El Tecn&oacute;logo Superior en Gesti&oacute;n del Talento Humano debe ser una persona con aptitudes y actitudes para:</span></p>\n' +
        '<ul style="list-style-type: disc;">\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Gestionar los procesos y subprocesos de gesti&oacute;n del talento humano, con visi&oacute;n integral, herramientas organizacionales y pertinencia social para la soluci&oacute;n de problemas profesionales</span></li>\n' +
        '    <li><span style=\'line-height:107%;font-family:"Times New Roman",serif;color:black;\'>Recomendar pol&iacute;ticas de administraci&oacute;n del talento humano que aporten al fortalecimiento de los objetivos y metas institucionales.</span></li>\n' +
        '    <li><span style=\'line-height:107%;font-family:"Times New Roman",serif;color:black;\'>Dirigir los procesos de gesti&oacute;n del talento humano que en base a su nivel de competencias en concordancia con la estrategia corporativa bajo una &oacute;ptica de calidad del servicio.</span></li>\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Planificar los procesos y subprocesos de talento humano, fomentando el trabajo colaborativo e inclusivo y promoviendo el bienestar de los colaboradores y la organizaci&oacute;n.</span></li>\n' +
        '    <li><span style=\'line-height:107%;font-family:"Times New Roman",serif;color:black;\'>Generar programas de acompa&ntilde;amiento a los empleados y trabajadores en su plan de carrera laboral, a fin de optimizar y mejorar sus competencias profesionales.</span></li>\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Capaces de promover el desarrollo del liderazgo comprometido que favorezca la instrumentaci&oacute;n de procesos de respuesta inmediata en contextos empresariales en el &aacute;rea.</span></li>\n' +
        '</ul>',
      title3: 'Perfil profesional',
      body3:
        '<p style=\'margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;\'><span style=\'font-size:16px;font-family:"Times New Roman",serif;color:black;\'>El Tecn&oacute;logo Superior en Gesti&oacute;n del Talento Humano es un profesional que podr&aacute;:</span></p>\n' +
        '<ul style="list-style-type: disc;">\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Dominar habilidades para colaborar en el planeamiento estrat&eacute;gico en instituciones p&uacute;blicas y privadas a trav&eacute;s del manejo de la normativa en el &aacute;mbito laboral.</span></li>\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Coordinar actividades de capacitaci&oacute;n, desarrollo y bienestar laboral eficientemente en la organizaci&oacute;n.</span></li>\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Coordinar actividades de capacitaci&oacute;n y desarrollo de competencias laborales eficientemente en la organizaci&oacute;n.</span></li>\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Apoyar el desarrollo de programas de Seguridad y Salud Ocupacional y riesgos laborales de acuerdo a normativa vigente.</span></li>\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Dise&ntilde;ar actividades de inducci&oacute;n para el nuevo personal y para los empleados que sean promovidos o transferidos de cargos respetando sus derechos.</span></li>\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Implementar acciones encaminadas a generar un clima organizacional favorable.</span></li>\n' +
        '</ul>',
      student: '20',
      ratings: '5',
      image: 'assets/image/carrera4.JPG',
      pdf: 'assets/pdf/pdf4.pdf',
    },
    {
      id: '5',
      name: 'TECNOLOGÍA SUPERIOR EN ADMINISTRACION',
      time: 'Duración: 5 periodos (2 años y medio)',
      title1: 'Perfil de ingreso',
      body1: this.text,
      title2: 'Perfil de egreso',
      body2:
        '<p style=\'margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;\'><span style=\'font-size:16px;font-family:"Times New Roman",serif;color:black;\'>El Tecn&oacute;logo Superior en Administraci&oacute;n debe ser una persona con aptitudes y actitudes para:</span></p>\n' +
        '<ul style="list-style-type: disc;">\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Identificar la base del ejercicio de su profesi&oacute;n en conformidad con los valores y la realidad del pensamiento cr&iacute;tico y de una manera &eacute;tica.</span></li>\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Desarrollar con un aporte en equipo, el desarrollo de las comunidades de acuerdo al compromiso con la sociedad de manera &eacute;tica.</span></li>\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Determinar las decisiones referentes a los datos y estudios estad&iacute;sticos, econ&oacute;micos para mejorar la marcha de la empresa</span></li>\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Analizar el microentorno econ&oacute;mico y sus agentes individuales como consumidores, empresas, trabajadores y los inversores de la empresa.</span></li>\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Describir la empresa a trav&eacute;s de entender los retos competitivos de acuerdo a la raz&oacute;n de ser de la organizaci&oacute;n y aplicando nuevos planes estrat&eacute;gicos viables para el desarrollo.</span></li>\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Desarrollar un proyecto, creando o mejorando una empresa (micro, peque&ntilde;as o medianas), gestionada por medio de m&eacute;todos y procesos administrativo</span></li>\n' +
        '    <li><span style=\'line-height:115%;font-family:"Times New Roman",serif;font-size:16px;\'>Eval&uacute;a procesos que integran la cadena de valor mediante una gesti&oacute;n eficiente que permite optimizar los recursos de la organizaci&oacute;n.</span></li>\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Analizar el comportamiento de los mercados y de los consumidores, buscando siempre oportunidades</span></li>\n' +
        '    <li><span style=\'line-height:115%;font-family:"Times New Roman",serif;font-size:16px;\'>Dise&ntilde;a sistemas de organizaci&oacute;n considerando alternativas estrat&eacute;gicas que generen cadenas productivas en beneficio de la sociedad.</span></li>\n' +
        '</ul>',
      title3: 'Perfil profesional',
      body3:
        '<p style=\'margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;\'><span style=\'font-size:16px;font-family:"Times New Roman",serif;color:black;\'>El Tecn&oacute;logo Superior en Administraci&oacute;n es un profesional l&iacute;der con esp&iacute;ritu emprendedor de iniciativa sostenida, con una visi&oacute;n sistem&aacute;tica del mundo y de la empresa, con sentido &eacute;tico y, por lo tanto, administrador de valores, profesional, capaz de generar cambios en empresas nacionales e internacionales, de acuerdo con las competencias particulares de su profesi&oacute;n.</span></p>\n' +
        '<p style=\'margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;\'><span style=\'font-size:16px;font-family:"Times New Roman",serif;color:black;\'>El Administrador de Empresas, estar&aacute; en capacidad de generar empresas o ser gerente en el sector real de la producci&oacute;n o en el sector comercial y ocupar espacios de desarrollo en las &aacute;reas de: mercadeo, finanzas, producci&oacute;n.</span></p>\n' +
        '<p style=\'margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;\'><span style=\'font-size:16px;font-family:"Times New Roman",serif;color:black;\'>Igualmente, podr&aacute; construir redes de investigaci&oacute;n con profesionales de su misma disciplina e interpretar el mundo de la tecnolog&iacute;a y del aprendizaje virtual.</span></p>',
      student: '20',
      ratings: '5',
      image: 'assets/image/carrera5.JPG',
      pdf: 'assets/pdf/pdf5.pdf',
    },
    {
      id: '6',
      name: 'GUÍA NACIONAL DE TURISMO CON NIVEL EQUIVALENTE A TÉCNICO SUPERIOR',
      time: 'Duración: 2 periodos (1 año)',
      title1: 'Perfil de ingreso',
      body1: this.text,
      title2: 'Perfil de egreso',
      body2:
        '<p style=\'margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:150%;font-family:"Times New Roman",serif;\'>El egresado en Gu&iacute;a Nacional de Turismo con nivel equivalente a T&eacute;cnico Superior una vez culminado su proceso de formaci&oacute;n profesional, tendr&aacute; s&oacute;lidos conocimientos en.</span></p>\n' +
        '<ul style="list-style-type: disc;">\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Desempe&ntilde;ar funciones en relaci&oacute;n a los est&aacute;ndares de calidad de los servicios tur&iacute;sticos para promover la Cultura de Excelencia en un contexto de imagen nacional. Igualmente, podr&aacute; construir redes de investigaci&oacute;n con profesionales de su misma disciplina e interpretar el mundo de la tecnolog&iacute;a y del aprendizaje virtual.</span></li>\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Acompa&ntilde;ar, orientar y transmitir informaci&oacute;n a personas o grupos, durante el trayecto de la excursi&oacute;n, adoptando todas las atribuciones de naturaleza t&eacute;cnica y administrativa necesarias para la fiel ejecuci&oacute;n del programa, lo que comprende recibir, trasladar y acomodar al turista</span></li>\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Crear y comercializar productos tur&iacute;sticos innovadores en torno a la demanda nacional e internacional de manera sostenible.</span></li>\n' +
        '    <li><span style=\'line-height:107%;font-family:"Times New Roman",serif;color:black;\'>Gestionar actividades tur&iacute;sticas y proyectos en beneficio de los actores de la sociedad y la empresa p&uacute;blica, privada y comunitaria que impulsen el desarrollo tur&iacute;stico.</span></li>\n' +
        '    <li><span style=\'line-height:107%;font-family:"Times New Roman",serif;color:black;\'>Guiar a grupos de turistas nacionales y extranjeros e interpretar el patrimonio cultural y natural con la finalidad de incentivar la valoraci&oacute;n y promoci&oacute;n de los mismos.</span></li>\n' +
        '    <li><span style=\'line-height:107%;font-family:"Times New Roman",serif;color:black;\'>Ejecutar, reglamentos, disposiciones de los &oacute;rganos reguladores para la operatividad y las normas de seguridad tur&iacute;sticas establecidas para velar por la integridad f&iacute;sica de los turistas.</span></li>\n' +
        '</ul>',
      title3: 'Perfil profesional',
      body3:
        '<p style=\'margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;\'><span style=\'font-size:16px;font-family:"Times New Roman",serif;color:black;\'>El&nbsp;</span><span style=\'font-size:16px;font-family:"Times New Roman",serif;color:black;\'>Gu&iacute;a Nacional de Turismo con nivel equivalente a T&eacute;cnico Superior</span><span style=\'font-size:16px;font-family:"Times New Roman",serif;color:black;\'>&nbsp;debe ser una persona con aptitudes y actitudes para:</span></p>\n' +
        '<ul style="list-style-type: disc;">\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Guiar grupos de turistas nacionales e internacionales dentro del territorio nacional en correspondencia a los principios de Calidad Tur&iacute;stica.</span></li>\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Gestionar procesos y proponer estrategias que faciliten la toma de decisiones dentro de la organizaci&oacute;n con competencia tur&iacute;stica.</span></li>\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Generar proyectos y emprendimientos tur&iacute;sticos desde una perspectiva de aporte, mejora o innovaci&oacute;n de un recurso o atractivo tur&iacute;stico con impacto social.</span></li>\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Dise&ntilde;ar creativos paquetes tur&iacute;sticos vanguardistas y comercializarlos de manera efectiva hacia un p&uacute;blico objetivo espec&iacute;fico.</span></li>\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Emplear habilidades sociales y de autocontrol con el fin de dar soluciones adecuadas a las complicaciones que puedan suscitarse en el ejercicio de sus funciones.</span></li>\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Interpretar el patrimonio nacional y cultural de una manera clara y din&aacute;mica.</span></li>\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Aplicar el pensamiento cr&iacute;tico propositivo como aporte al desarrollo profesional para generar conocimiento</span></li>',
      student: '20',
      ratings: '5',
      image: 'assets/image/carrera6.jpeg',
      pdf: 'assets/pdf/pdf6.pdf',
    },
    {
      id: '7',
      name: 'TECNOLOGÍA SUPERIOR EN ACTIVIDAD FÍSICA DEPORTIVA Y RECREACIÓN',
      time: 'Duración: 4 periodos (2 años)',
      title1: 'Perfil de ingreso',
      body1: this.text,
      title2: 'Perfil de egreso',
      body2:
        '<p style=\'margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:150%;font-family:"Times New Roman",serif;\'>El egresado en Tecnolog&iacute;a Superior en&nbsp;</span><span style=\'font-size:16px;line-height:150%;font-family:"Times New Roman",serif;\'>Actividad F&iacute;sica Deportiva y Recreaci&oacute;n</span><span style=\'font-size:16px;line-height:150%;font-family:"Times New Roman",serif;\'>&nbsp;una vez culminado su proceso de formaci&oacute;n profesional, podr&aacute;n desempe&ntilde;arse como:</span></p>\n' +
        '<p style=\'margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:150%;font-family:"Times New Roman",serif;\'>Preparador f&iacute;sico:</span></p>\n' +
        '<p style=\'margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:150%;font-family:"Times New Roman",serif;\'>Integrante de grupos interdisciplinarios aportando en los procesos de creaci&oacute;n, acompa&ntilde;amiento, asesoramiento y evaluaci&oacute;n de proyectos deportivos desde la iniciaci&oacute;n y formaci&oacute;n deportiva, el rendimiento deportivo y la competencia deportiva.</span></p>\n' +
        '<p style=\'margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:150%;font-family:"Times New Roman",serif;\'>Responsable de deportistas o grupos deportivos aportando en la preparaci&oacute;n f&iacute;sica y apoyo en la preparaci&oacute;n psicol&oacute;gica desde la iniciaci&oacute;n y formaci&oacute;n deportiva, el rendimiento deportivo y la competencia deportiva.</span></p>\n' +
        '<p style=\'margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:150%;font-family:"Times New Roman",serif;\'>Investigador deportivo:</span></p>\n' +
        '<p style=\'margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:150%;font-family:"Times New Roman",serif;\'>Desde su din&aacute;mica e intervenci&oacute;n en la Universidad, puede participar en semilleros, l&iacute;neas y grupos de investigaci&oacute;n, que le permite apropiarse desde la teor&iacute;a, la pr&aacute;ctica y la investigaci&oacute;n, conocer las necesidades, problem&aacute;ticas y expectativas del sector, proyectando sus resultados y productos hacia la transformaci&oacute;n de la sociedad.</span></p>\n' +
        '<p style=\'margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:150%;font-family:"Times New Roman",serif;\'>Dirigente deportivo:</span></p>\n' +
        '<p style=\'margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:150%;font-family:"Times New Roman",serif;\'>Tiene formaci&oacute;n en administraci&oacute;n por proyectos, gesti&oacute;n de instalaciones y legislaci&oacute;n deportiva, que le permiten aportar a los procesos de gerencia en las organizaciones del sector; puede ser coordinador o l&iacute;der de &aacute;reas que desarrollen eventos deportivos con diferentes deportes y grupos poblacionales.</span></p>\n' +
        '<p style=\'margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:150%;font-family:"Times New Roman",serif;\'>L&iacute;der deportivo-comunitario:</span></p>\n' +
        '<p style=\'margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;\'><span style=\'font-size:16px;line-height:150%;font-family:"Times New Roman",serif;\'>Tiene formaci&oacute;n pedag&oacute;gica y did&aacute;ctica para coordinar, orientar y liderar procesos comunitarios, con estrategias de apoyo, habilidades comunicativas, el control emocional y uso de herramientas tecnol&oacute;gicas para la pr&aacute;ctica de deportes individuales, de conjunto, extremos y alternativos con diferentes grupos poblacionales.</span></p>',
      title3: 'Perfil profesional',
      body3:
        '<p style=\'margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;\'><span style=\'font-size:16px;font-family:"Times New Roman",serif;color:black;\'>El&nbsp;</span><span style=\'font-size:16px;font-family:"Times New Roman",serif;color:black;\'>Tecn&oacute;logo Superior en&nbsp;</span><span style=\'font-size:16px;font-family:"Times New Roman",serif;color:black;\'>Actividad F&iacute;sica Deportiva y Recreaci&oacute;n</span><span style=\'font-size:16px;font-family:"Times New Roman",serif;color:black;\'>&nbsp;debe ser una persona con aptitudes y actitudes para:</span></p>\n' +
        '<ul style="list-style-type: disc;">\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Maneja los fundamentos te&oacute;rico-cient&iacute;ficos del deporte para la formaci&oacute;n y desarrollo del ser humano.</span></li>\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Investiga y apropia a su quehacer profesional los avances de la ciencia y&nbsp;de la tecnolog&iacute;a aplicados al deporte.</span></li>\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Conoce, dise&ntilde;a, transfiere, adapta y aplica las tecnolog&iacute;as y metodolog&iacute;as propias del entrenamiento deportivo, en el contexto del movimiento deportivo.</span></li>\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Conoce, desarrolla y aplica las competencias profesionales espec&iacute;ficas relacionadas con el entrenamiento deportivo, de acuerdo con las caracter&iacute;sticas particulares de la persona, deporte, modalidad y objetivos del entrenamiento deportivo.</span></li>\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Realiza procesos de emprendimiento deportivo para la creaci&oacute;n de peque&ntilde;as y medianas organizaciones deportivas y de actividad f&iacute;sica.</span></li>\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Conciencia de la importancia antropol&oacute;gica, sociol&oacute;gica, pol&iacute;tica, econ&oacute;mica y administrativa del deporte y sus manifestaciones deportivas para el desarrollo de la sociedad, con &eacute;nfasis en salud y educaci&oacute;n.</span></li>\n' +
        '    <li><span style=\'font-family:"Times New Roman",serif;font-size:12.0pt;color:black;\'>Involucra en su quehacer profesional los principios fundamentales de la &eacute;tica deportiva, el respeto por la condici&oacute;n humana y el prop&oacute;sito de elevar el nivel humano, social y las posibilidades t&eacute;cnicas de sus deportistas.</span></li>\n' +
        '</ul>',
      student: '20',
      ratings: '5',
      image: 'assets/image/valores.JPG',
      pdf: 'assets/pdf/pdf7.pdf',
    },
  ];

  fileRef = [];

  constructor() {}

  valSesion() {
    if (
      localStorage.getItem('currentUser') != null &&
      localStorage.getItem('currentUser') != ''
    ) {
      return true;
    }
    return false;
  }
}
