import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';

import { FileItem } from 'src/app/shared/models/file-item';
import { SharedServiceService } from '../sharedService/shared-service.service';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private MEDIA_STORAGE_PATH = 'ita';

  constructor(
    private readonly storage: AngularFireStorage,
    private sharedService: SharedServiceService
  ) {}

  uploadImages(images: FileItem[]) {
    for (const item of images) {
      item.uploading = true;

      const filePath = this.generateFileName(item.name);
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, item.file);

      item.progress = task.percentageChanges();

      task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            item.downloadURL = fileRef.getDownloadURL();
            item.uploading = false;
          })
        )
        .subscribe();
    }
  }

  uploadImage(item: FileItem) {
    item.uploading = true;

    const filePath = this.generateFileName(item.name);
    this.sharedService.fileRef[0] = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, item.file);

    item.progress = task.percentageChanges();

    return task;
  }

  private generateFileName(name: string): string {
    return `${this.MEDIA_STORAGE_PATH}/${new Date().getTime()}_${name}`;
  }

  delete(url: string) {
    return this.storage.storage.refFromURL(url).delete();
  }
}
