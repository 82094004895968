<div class="home">
  <div class="breadcrumbs_container">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="breadcrumbs">
            <ul>
              <li><a routerLink="/">ITA</a></li>
              <li><a routerLink="/courses">Carreras</a></li>
              <li>{{ course.name }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="course">
  <div class="container">
    <div class="row">

      <div class="col-lg-8">
        <div class="course_container">
          <div class="course_title">{{ course.name }}</div>
          <div
            class="course_info d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-start">

            <div class="course_info_item">
              <div class="course_info_title">Malla Curricular:</div>
            </div>

            <div class="course_info_item">
              <div class="course_info_title"><a href="{{ course.pdf}}" target="_blank">Descargar</a></div>
            </div>

          </div>

          <div class="course_image"><img src="{{ course.image }}" alt=""></div>

          <div class="course_tabs_container">
            <div class="tabs d-flex flex-row align-items-center justify-content-start">
              <div class="tab active">{{ course.title1 }}</div>
              <div class="tab">{{ course.title2 }}</div>
              <div class="tab">{{ course.title3 }}</div>
            </div>
            <div class="tab_panels">

              <div class="tab_panel active">
                <div class="tab_panel_title">{{ course.title1 }}</div>
                <div class="tab_panel_content">
                  <div class="tab_panel_text" [innerHTML]="course.body1">

                  </div>
                </div>
              </div>

              <div class="tab_panel tab_panel_2">
                <div class="tab_panel_title">{{ course.title2 }}</div>
                <div class="tab_panel_content">
                  <div class="tab_panel_text" [innerHTML]="course.body2">

                  </div>
                </div>
              </div>

              <div class="tab_panel tab_panel_3">
                <div class="tab_panel_title">{{ course.title3 }}</div>
                <div class="tab_panel_content">
                  <div class="tab_panel_text" [innerHTML]="course.body3">

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="sidebar">
          <div class="sidebar_section">
            <div class="sidebar_section_title">Todos las carreras</div>
            <div class="sidebar_latest">

              <div class="latest d-flex flex-row align-items-start justify-content-start" *ngFor="let item of courses">
                <div class="latest_image">
                  <div><img src="{{ item.image }}" alt=""></div>
                </div>
                <div class="latest_content">
                  <div class="latest_title"><a (click)="change(item.id)"> {{ item.name }} </a></div>
                  <div class="latest_price"></div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
