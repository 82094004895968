<div class="home" style="margin-top: 30px">
  <div class="home_slider_container">

    <div class="owl-carousel owl-theme home_slider">

      <div class="owl-item">
        <div class="home_slider_background" style="background-image:url(assets/image/Portada\ 1.JPG)">
        </div>
        <div class="home_slider_background" style="background-color: black; opacity: 0.25;">
        </div>
        <div class="home_slider_content">
          <div class="container">
            <div class="row">
              <div class="col text-center">
                <div class="home_slider_title" style="color: white; margin-top: 270px; font-size: 46px;">
                  Instituto Superior Tecnológico Andino
                </div>
                <div class="home_slider_subtitle" style="color: white;">
                  Reconocimiento y respeto de todas las formas de conocimiento local y nacional
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="owl-item">
        <div class="home_slider_background" style="background-image:url(assets/image/Portada\ 2.JPG)">
        </div>
        <div class="home_slider_background" style="background-color: black; opacity: 0.25;">
        </div>
        <div class="home_slider_content">
          <div class="container">
            <div class="row">
              <div class="col text-center">
                <div class="home_slider_title" style="color: white; margin-top: 270px; font-size: 46px;">
                  Instituto Superior Tecnológico Andino
                </div>
                <div class="home_slider_subtitle" style="color: white;">
                  Apoyar el desarrollo de las personas, las instituciones y organizaciones.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="owl-item">
        <div class="home_slider_background"
          style="background-image:url(assets/image/Portada\ 3.JPG); background-position: top;">
        </div>
        <div class="home_slider_background" style="background-color: black; opacity: 0.25;">
        </div>
        <div class="home_slider_content">
          <div class="container">
            <div class="row">
              <div class="col text-center">
                <div class="home_slider_title" style="color: white; margin-top: 270px; font-size: 46px;">
                  Instituto Superior Tecnológico Andino
                </div>
                <div class="home_slider_subtitle" style="color: white;">
                  Apoyar a las personas en su crecimiento profesional.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="owl-item">
        <div class="home_slider_background" style="background-image:url(assets/image/Portada\ 4.JPG)">
        </div>
        <div class="home_slider_background" style="background-color: black; opacity: 0.25;">
        </div>
        <div class="home_slider_content">
          <div class="container">
            <div class="row">
              <div class="col text-center">
                <div class="home_slider_title" style="color: white; margin-top: 270px; font-size: 46px;">
                  Instituto Superior Tecnológico Andino
                </div>
                <div class="home_slider_subtitle" style="color: white;">
                  Fomentar la excelencia, la calidad y la innovación en todos los ámbitos de gestión y académicos.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="owl-item">
        <div class="home_slider_background" style="background-image:url(assets/image/Portada\ 5.JPG)">
        </div>
        <div class="home_slider_background" style="background-color: black; opacity: 0.25;">
        </div>
        <div class="home_slider_content">
          <div class="container">
            <div class="row">
              <div class="col text-center">
                <div class="home_slider_title" style="color: white; margin-top: 270px; font-size: 46px;">
                  Instituto Superior Tecnológico Andino
                </div>
                <div class="home_slider_subtitle" style="color: white;">
                  Promover el desarrollo sustentable, la responsabilidad social, y la ética profesional.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="home_slider_nav home_slider_prev"><i class="fa fa-angle-left" aria-hidden="true"></i></div>
  <div class="home_slider_nav home_slider_next"><i class="fa fa-angle-right" aria-hidden="true"></i></div>
</div>

<div class="features">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="section_title_container text-center">
          <h2 class="section_title">Modalidades</h2>
          <div class="section_subtitle">
            <!--             <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel gravida arcu.
              Vestibulum feugiat, sapien ultrices fermentum congue, quam velit venenatis sem</p> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row features_row">

      <div class="col-lg-3 feature_col">
        <div class="feature text-center trans_400">
          <div class="feature_icon"><img src="/assets/images/icon_2.png" alt=""></div>
          <h3 class="feature_title">Presencial Matutina</h3>
          <div class="feature_text">
            <p>Lunes a viernes a partir de las 08:00</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 feature_col">
        <div class="feature text-center trans_400">
          <div class="feature_icon"><img src="/assets/images/icon_2.png" alt=""></div>
          <h3 class="feature_title">Presencial Nocturna</h3>
          <div class="feature_text">
            <p>Lunes a viernes a partir de las 18:00</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 feature_col">
        <div class="feature text-center trans_400">
          <div class="feature_icon"><img src="/assets/images/icon_2.png" alt=""></div>
          <h3 class="feature_title">Semipresencial</h3>
          <div class="feature_text">
            <p>Sábados desde las 08:00</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 feature_col">
        <div class="feature text-center trans_400">
          <div class="feature_icon"><img src="/assets/images/icon_2.png" alt=""></div>
          <h3 class="feature_title">Online</h3>
          <div class="feature_text">
            <p>Aula virtual ITA</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="courses">
  <div class="section_background parallax-window" data-parallax="scroll"
    data-image-src="/assets/images/courses_background.jpg" data-speed="0.8"></div>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="section_title_container text-center">
          <h2 class="section_title">Nuestras carreras</h2>
          <div class="section_subtitle">
            <!--        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel gravida arcu.
              Vestibulum feugiat, sapien ultrices fermentum congue, quam velit venenatis sem</p> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row courses_row">

      <div class="col-lg-4 course_col" *ngFor="let item of courses.slice(0, 3);">
        <div class="course">
          <div class="course_image"><img src="{{ item.image }}" alt=""
              style="width: 100%; max-height: 200px; object-fit: cover;"></div>
          <div class="course_body">
            <h3 class="course_title"><a routerLink="/course/{{ item.id }}">{{ item.name }}</a></h3>
            <!-- <div class="course_teacher">Mr. John Taylor</div> -->
            <div class="course_text">
              <p>{{ item.time }}</p>
            </div>
          </div>
          <div class="course_footer">
            <div class="course_footer_content d-flex flex-row align-items-center justify-content-start">
              <div class="course_info">
                <i class="fa fa-graduation-cap" aria-hidden="true"></i>
                <span>{{ item.student }} Estudiantes</span>
              </div>
              <div class="course_info">
                <i class="fa fa-star" aria-hidden="true"></i>
                <span>{{ item.ratings }} Calificaciones</span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col">
        <div class="courses_button trans_200"><a routerLink="/courses">ver todas las carreras</a></div>
      </div>
    </div>
  </div>
</div>

<div class="counter">
  <div class="counter_background" style="background-color: #0F4B9B;"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="counter_content">
          <h2 class="counter_title">Inscríbete ahora</h2>
          <div class="counter_text">
            <p> Apoyar el desarrollo de las personas, las instituciones y organizaciones.</p>
          </div>

          <div class="milestones d-flex flex-md-row flex-column align-items-center justify-content-between">

            <div class="milestone">
              <div class="milestone_counter" data-end-value="12" data-sign-after="k" style="color: white">0</div>
              <div class="milestone_text">Visitas</div>
            </div>

            <div class="milestone">
              <div class="milestone_counter" data-end-value="100" data-sign-after="+" style="color: white">0</div>
              <div class="milestone_text">Estudiantes</div>
            </div>

            <div class="milestone">
              <div class="milestone_counter" data-end-value="7" style="color: white">0</div>
              <div class="milestone_text">Carreras</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="counter_form" style="margin-top: 30px; padding-top: 15px;">
      <div class="row fill_height">
        <div class="col fill_height">
          <form class="counter_form_content d-flex flex-column align-items-center justify-content-center"
            [formGroup]="messageForm" (ngSubmit)="sendMessage()">
            <div class="counter_form_title">Contactanos</div>
            <input type="text" class="counter_input" placeholder="Nombre:" formControlName="name" matInpu
              required="required">
            <input type="tel" class="counter_input" placeholder="Telefono:" formControlName="phone" matInpu
              required="required">
            <input type="email" class="counter_input" placeholder="Correo Electrónico:" formControlName="email" matInpu
              required="required">
            <textarea class="counter_input counter_text_input" placeholder="Mensaje:" formControlName="text" matInpu
              required="required"></textarea>
            <button type="submit" class="counter_form_button">Enviar</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="team">
  <div class="team_background parallax-window" data-parallax="scroll"
    data-image-src="/assets/images/courses_background.jpg" data-speed="0.8"></div>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="section_title_container text-center">
          <h2 class="section_title">Nuestro Equipo</h2>
          <div class="section_subtitle">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel gravida arcu.
              Vestibulum feugiat, sapien ultrices fermentum congue, quam velit venenatis sem</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row team_row">

      <div class="col-lg-3 col-md-6 team_col">
        <div class="team_item">
          <div class="team_image"><img src="assets/image/IMG_2706.JPG" alt=""></div>
          <div class="team_body">
            <div class="team_title"><a href="#">Jacke Masito</a></div>
            <div class="team_subtitle">Profesor</div>
            <div class="social_list">
              <ul>
                <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                <li><a href="#"><i class="fa fa-google-plus" aria-hidden="true"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 team_col">
        <div class="team_item">
          <div class="team_image"><img src="assets/image/IMG_2706.JPG" alt=""></div>
          <div class="team_body">
            <div class="team_title"><a href="#">Jacke Masito</a></div>
            <div class="team_subtitle">Profesor</div>
            <div class="social_list">
              <ul>
                <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                <li><a href="#"><i class="fa fa-google-plus" aria-hidden="true"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 team_col">
        <div class="team_item">
          <div class="team_image"><img src="assets/image/IMG_2706.JPG" alt=""></div>
          <div class="team_body">
            <div class="team_title"><a href="#">Jacke Masito</a></div>
            <div class="team_subtitle">Profesor</div>
            <div class="social_list">
              <ul>
                <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                <li><a href="#"><i class="fa fa-google-plus" aria-hidden="true"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 team_col">
        <div class="team_item">
          <div class="team_image"><img src="assets/image/IMG_2706.JPG" alt=""></div>
          <div class="team_body">
            <div class="team_title"><a href="#">Jacke Masito</a></div>
            <div class="team_subtitle">Profesor</div>
            <div class="social_list">
              <ul>
                <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                <li><a href="#"><i class="fa fa-google-plus" aria-hidden="true"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div> -->

<div class="news">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="section_title_container text-center">
          <h2 class="section_title">Últimas noticias</h2>
          <div class="section_subtitle">
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel gravida arcu.
              Vestibulum feugiat, sapien ultrices fermentum congue, quam velit venenatis sem</p> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row news_row">
      <div class="col-lg-8 news_col">


        <div class="news_post_large_container" *ngIf="news[0] as item1">
          <div class="news_post_large">
            <div class="news_post_image"><img [src]="item1.img" alt="" style="width: 100%; max-height: 400px;"></div>
            <div class="news_post_large_title"><a href="#">{{item1.title}}</a></div>
            <div class="news_post_meta">
              <ul>
                <li><a>admin</a></li>
                <li><a>{{ item1.date | date: 'yyyy-MM-dd' }}</a></li>
              </ul>
            </div>
            <div class="news_post_text">
              <p>{{item1.text}}</p>
            </div>
            <!-- <div class="news_post_link"><a href="blog_single.html">read more</a></div> -->
          </div>
        </div>

      </div>
      <div class="col-lg-4 news_col">
        <div class="news_posts_small" *ngFor="let item of news.slice(1,5)">

          <div class="news_post_small" style="margin-bottom: 40px;">
            <div class="news_post_small_title">
              <a href="#">
                {{item.title}}
              </a>
            </div>
            <div class="news_post_meta">
              <ul>
                <li><a>admin</a></li>
                <li><a>{{ item.date | date: 'yyyy-MM-dd' }}</a></li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
