import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-message',
  templateUrl: './edit-message.component.html',
  styleUrls: ['./edit-message.component.scss']
})
export class EditMessageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
