<div class="home">
  <div class="breadcrumbs_container">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="breadcrumbs">
            <ul>
              <li><a routerLink="/">ITA</a></li>
              <li>Contactos</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="contact">

  <div class="contact_map">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d997.4148711338554!2d-78.56555987082324!3d-0.5112539999766835!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91d5af90cefa3d4b%3A0x1f72f95ccf321176!2sInstituto%20Superior%20Tecnol%C3%B3gico%20Andino!5e0!3m2!1ses-419!2sec!4v1625876567855!5m2!1ses-419!2sec"
      width="100%" height="600" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
  </div>

  <div class="contact_info_container">
    <div class="container">
      <div class="row">

        <div class="col-lg-6">
          <div class="contact_form">
            <div class="contact_info_title">Formulario</div>
            <form [formGroup]="messageForm" (ngSubmit)="sendMessage()">
              <div>
                <label class="form_title" for="email">Nombre</label>
                <input class="comment_input" type="text" formControlName="name" matInput required />
                <mat-error *ngIf="isValidField('name')">
                  {{ getErrorMessage("name") }}
                </mat-error>
              </div>
              <div>
                <label class="form_title" for="phone">Teléfono</label>
                <input class="comment_input" type="tel" formControlName="phone" matInput required />
                <mat-error *ngIf="isValidField('phone')">
                  {{ getErrorMessage("phone") }}
                </mat-error>
              </div>
              <div>
                <label class="form_title" for="email">Correo Electrónico</label>
                <input class="comment_input" type="email" formControlName="email" matInput required />
                <mat-error *ngIf="isValidField('email')">
                  {{ getErrorMessage("email") }}
                </mat-error>
              </div>
              <div>
                <label class="form_title" for="text">Mensaje</label>
                <textarea class="comment_input comment_textarea" formControlName="text" matInput required></textarea>
                <mat-error *ngIf="isValidField('text')">
                  {{ getErrorMessage("text") }}
                </mat-error>
              </div>
              <div>
                <button type="submit" class="comment_button trans_200">Enviar Ahora</button>
              </div>
            </form>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="contact_info">
            <div class="contact_info_title">Información de contacto</div>
            <div class="contact_info_text">
              <!--               <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel gravida arcu.
                Vestibulum feugiat, sapien ultrices fermentum congue, quam velit venenatis sem.</p> -->
            </div>
            <div class="contact_info_location">
              <div class="contact_info_location_title">PICHINCHA: MEJÍA</div>
              <ul class="location_list">
                <li>MACHAHI: JOSÉ MEJÍA E-190 Y ANTONIO JOSÉ DE SUCRE</li>
                <li>(02) 231 6236</li>
                <li>099 504 5165</li>
                <li>info.istandino@gmail.com</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
