<app-spinner *ngIf="isLoading"></app-spinner>

<div [hidden]="isLoading">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event)" placeholder="Buscar" />
  </mat-form-field>

  <div class="container-btn-new">
    <button mat-stroked-button (click)="onNewNew()">
      <mat-icon>note_add</mat-icon>
      Nueva Noticia
    </button>
  </div>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Titulo</th>
      <td mat-cell *matCellDef="let element">{{ element.title }}</td>
    </ng-container>

    <ng-container matColumnDef="text">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Texto</th>
      <td mat-cell *matCellDef="let element">{{ element.text }}</td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha</th>
      <td mat-cell *matCellDef="let element">{{ element.date | date: 'yyyy-MM-dd' }}</td>
    </ng-container>

    <ng-container matColumnDef="img">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Foto</th>
      <td mat-cell *matCellDef="let element"><a [href]="element.img" target="_blank">
        Ver Imagen
      </a></td>
    </ng-container>


    <ng-container matColumnDef="options">
      <th mat-header-cell *matHeaderCellDef>Options</th>
      <td mat-cell *matCellDef="let element">
        <button mat-stroked-button color="accent" (click)="onEditNew(element)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-stroked-button color="warn" (click)="onDeleteNew(element)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
</div>
