<div class="home">
  <div class="breadcrumbs_container">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="breadcrumbs">
            <ul>
              <li><a routerLink="/">ITA</a></li>
              <li>Iniciar sesión</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="login">
  <div class="form">
    <div class="form-toggle"></div>
    <div class="form-panel one">
      <div class="form-header">
        <h1>CUENTA DE INGRESO</h1>
      </div>
      <div class="form-content">
        <form [formGroup]="loginForm" (ngSubmit)="login()">
          <div class="form-group">
            <label for="email">Correo Electrónico</label>
            <input type="email" formControlName="email" matInput required />
            <mat-error *ngIf="isValidField('email')">
              {{ getErrorMessage("email") }}
            </mat-error>
          </div>
          <div class="form-group">
            <label for="username">Contraseña</label>
            <input type="password" formControlName="password" matInput minLength="8" required />
            <mat-error *ngIf="isValidField('password')">
              {{ getErrorMessage("password") }}
            </mat-error>
          </div>

          <!-- <div class="form-group">
            <label class="form-remember"><input type="checkbox" />Recordar</label>
            <a class="form-recovery" href="#">Forgot Password?</a>
          </div> -->
          <div class="form-group">
            <button type="submit">Iniciar</button>
          </div>
        </form>
      </div>
    </div>
    <div class="form-panel two">
      <div class="form-header">
        <h1>Register Account</h1>
      </div>
      <div class="form-content">
        <form>
          <div class="form-group"><label for="username">Username</label><input type="text" id="username" name="username"
              required="required" /></div>
          <div class="form-group"><label for="password">Password</label><input type="password" id="password"
              name="password" required="required" /></div>
          <div class="form-group"><label for="cpassword">Confirm Password</label><input type="password" id="cpassword"
              name="cpassword" required="required" /></div>
          <div class="form-group"><label for="email">Email Address</label><input type="email" id="email" name="email"
              required="required" /></div>
          <div class="form-group"><button type="submit">Register</button></div>
        </form>
      </div>
    </div>
  </div>
</div>
