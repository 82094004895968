import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SharedServiceService } from 'src/app/services/sharedService/shared-service.service';
import { WsItaService } from 'src/app/services/wsIta/ws-ita.service';
declare const loginPage: any;
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginForm: any;

  constructor(
    private router: Router,
    private ws: WsItaService,
    private ss: SharedServiceService
  ) {}

  ngOnInit(): void {
    if (this.ss.valSesion()) {
      this.router.navigate(['admin/messages']);
    }
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
      ]),
    });
  }

  ngAfterViewInit(): void {
    /* loginPage(); */
  }

  async login() {
    if (this.loginForm.valid) {
      const datos = await new Promise<any>((resolve) =>
        this.ws.login(this.loginForm.value).subscribe((translated) => {
          resolve(translated);
        })
      );

      if (datos.login) {
        localStorage.setItem('currentUser', JSON.stringify(datos.res));
        /* this.ss.currentUser = datos.res; */
        this.router.navigate(['admin/messages']);
      } else {
        Swal.fire('Error', datos.message, 'warning');
      }
    }
  }

  getErrorMessage(field: string): string {
    let message = '';
    if (this.loginForm.get(field).errors.required) {
      message = 'Ingresar un valor';
    } else if (this.loginForm.get(field).hasError('email')) {
      message = 'No es un email valido';
    } else if (this.loginForm.get(field).hasError('minlength')) {
      message = 'Ingrese mínimo de 8 caracteres';
    }
    return message;
  }

  isValidField(field: string): boolean {
    return (
      (this.loginForm.get(field).touched || this.loginForm.submitted) &&
      !this.loginForm.get(field).valid
    );
  }
}
