<div class="home">
  <div class="breadcrumbs_container">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="breadcrumbs">
            <ul>
              <li><a routerLink="/">ITA</a></li>
              <li>Nosotros</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="about">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="section_title_container text-center">
          <h2 class="section_title">Instituto Superior Tecnológico Andino</h2>
          <div class="section_subtitle">
            <p>El Instituto Superior Tecnológico Andino (ITA) es una institución de educación superior creada con el
              objetivo de brindar a la sociedad una formación integra con valores y resultados profesionales.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row about_row">

      <div class="col-lg-6 about_col about_col_left">
        <div class="about_item">
          <div class="about_item_image">
            <!-- <img src="images/about_1.jpg" alt=""> -->
          </div>
          <div class="about_item_title"><a>Misión</a></div>
          <div class="about_item_text">
            <p>El Instituto Superior Tecnológico Andino es una institución de educación superior que busca satisfacer
              las necesidades de los diversos alumnos del cantón Mejía y del resto del Ecuador. Esto se alcanzará a
              través de programas académicos de alta calidad impartidos a través de una metodología de estudio
              presencial, semipresencial y en línea centrada en el alumno y orientada a que cada uno de ellos alcance
              sus competencias.</p>
          </div>
        </div>
      </div>

      <div class="col-lg-6 about_col about_col_right">
        <div class="about_item">
          <div class="about_item_image">
            <!-- <img src="images/about_3.jpg" alt=""> -->
          </div>
          <div class="about_item_title"><a>Visión</a></div>
          <div class="about_item_text">
            <p>Ser reconocidos como el mejor Instituto Superior en el Ecuador formando profesionales técnicos y
              tecnólogos, guiados por los principios de excelencia, innovación, inclusión, responsabilidad educativa y
              social, generando impactos positivos en el Ecuador.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="feature">
  <div class="feature_background" style="background-image:url(assets/images/courses_background.jpg)"></div>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="section_title_container text-center">
          <h2 class="section_title">Valores</h2>
          <div class="section_subtitle">
            <!--             <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel gravida arcu.
              Vestibulum feugiat, sapien ultrices fermentum congue, quam velit venenatis sem</p> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row feature_row">

      <div class="col-lg-6 feature_col">
        <div class="feature_content">
          <div class="accordions">
            <div class="elements_accordions">
              <div class="accordion_container">
                <div class="textVal">
                  <div>Reconocimiento y respeto de todas las formas de conocimiento local y nacional.</div>
                </div>
                <div class="accordion_panel">
                  <p></p>
                </div>
              </div>
              <div class="accordion_container">
                <div class="textVal">
                  <div>Apoyar el desarrollo de las personas, las instituciones y organizaciones.</div>
                </div>
                <div class="accordion_panel">
                  <p></p>
                </div>
              </div>
              <div class="accordion_container">
                <div class="textVal">
                  <div>Apoyar a las personas en su crecimiento profesional.</div>
                </div>
                <div class="accordion_panel">
                  <p></p>
                </div>
              </div>
              <div class="accordion_container">
                <div class="textVal">
                  <div>Fomentar la excelencia, la calidad y la innovación en todos los ámbitos de gestión y académicos.
                  </div>
                </div>
                <div class="accordion_panel">
                  <p></p>
                </div>
              </div>
              <div class="accordion_container">
                <div class="textVal">
                  <div>Promover el desarrollo sustentable, la responsabilidad social, y la ética profesional.</div>
                </div>
                <div class="accordion_panel">
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 feature_col">
        <img src="assets/image/valores.JPG" alt="" width="100%">
      </div>
    </div>
  </div>
</div>

<!-- <div class="counter">
  <div class="counter_background" style="background-image:url(images/counter_background.jpg)"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="counter_content">
          <h2 class="counter_title">Register Now</h2>
          <div class="counter_text">
            <p>Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
              industry’s standard dumy text ever since the 1500s, when an unknown printer took a
              galley of type and scrambled it to make a type specimen book.</p>
          </div>

          <div class="milestones d-flex flex-md-row flex-column align-items-center justify-content-between">

            <div class="milestone">
              <div class="milestone_counter" data-end-value="15">0</div>
              <div class="milestone_text">years</div>
            </div>

            <div class="milestone">
              <div class="milestone_counter" data-end-value="120" data-sign-after="k">0</div>
              <div class="milestone_text">years</div>
            </div>

            <div class="milestone">
              <div class="milestone_counter" data-end-value="670" data-sign-after="+">0</div>
              <div class="milestone_text">years</div>
            </div>

            <div class="milestone">
              <div class="milestone_counter" data-end-value="320">0</div>
              <div class="milestone_text">years</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="counter_form">
      <div class="row fill_height">
        <div class="col fill_height">
          <form class="counter_form_content d-flex flex-column align-items-center justify-content-center" action="#">
            <div class="counter_form_title">courses now</div>
            <input type="text" class="counter_input" placeholder="Your Name:" required="required">
            <input type="tel" class="counter_input" placeholder="Phone:" required="required">
            <select name="counter_select" id="counter_select" class="counter_input counter_options">
              <option>Choose Subject</option>
              <option>Subject</option>
              <option>Subject</option>
              <option>Subject</option>
            </select>
            <textarea class="counter_input counter_text_input" placeholder="Message:" required="required"></textarea>
            <button type="submit" class="counter_form_button">submit now</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div> -->
