import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { SharedServiceService } from 'src/app/services/sharedService/shared-service.service';

@Injectable({
  providedIn: 'root',
})
export class CheckLoginGuard implements CanActivate {
  constructor(private ss: SharedServiceService, private router: Router) {}

  canActivate(): boolean {
    if (
      localStorage.getItem('currentUser') != null &&
      localStorage.getItem('currentUser') != ''
    ) {
      return true;
    } else {
      this.router.navigate(['login']);
      return false;
    }
  }
}
