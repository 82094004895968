import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedServiceService } from 'src/app/services/sharedService/shared-service.service';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss'],
})
export class CoursesComponent implements OnInit {
  public courses: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ss: SharedServiceService
  ) {}

  ngOnInit(): void {
    this.courses = this.ss.courses;
  }
}
