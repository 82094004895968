<div class="super_container">

  <header class="header">

    <div class="top_bar">
      <div class="top_bar_container">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="top_bar_content d-flex flex-row align-items-center justify-content-start">
                <ul class="top_bar_contact_list">
                  <li>
                    <div class="question">¿Tiene alguna pregunta?</div>
                  </li>
                  <li>
                    <i class="fa fa-phone" aria-hidden="true" style="padding-right: 5px;"></i>
                    <div> (02) 231 6236</div>
                  </li>
                  <li>
                    <i class="fa fa-mobile" aria-hidden="true" style="padding-right: 5px;"></i>
                    <div>
                      <a href="https://wa.me/593995045165" style="color: #fff">
                        099 504 5165
                      </a>
                    </div>
                  </li>
                  <li>
                    <i class="fa fa-envelope-o" aria-hidden="true" style="padding-right: 5px;"></i>
                    <div>
                      info.istandino@gmail.com
                    </div>
                  </li>
                </ul>
                <div class="top_bar_login ml-auto" *ngIf="!isLogin; else navSesion">
                  <div class="login_button"><a routerLink="/login">Iniciar sesión</a></div>
                </div>
                <ng-template #navSesion>
                  <div class="top_bar_login ml-auto">
                    <div class="login_button"><a routerLink="/admin/messages">Administrar</a></div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="header_container">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="header_content d-flex flex-row align-items-center justify-content-start">
              <div class="logo_container">
                <a routerLink="/">
                  <img src="assets/image/logos/logo.png" alt="" width="100px">
                </a>
              </div>
              <nav class="main_nav_contaner ml-auto">
                <ul class="main_nav">
                  <li routerLinkActive="active"><a routerLink="/home">Inicio</a></li>
                  <li routerLinkActive="active"><a routerLink="/about">Nosotros</a></li>
                  <li routerLinkActive="active"><a routerLink="/courses">Carreras</a></li>
                  <!-- <li><a href="blog.html">Blog</a></li> -->
                  <li routerLinkActive="active"><a routerLink="/contact">Contactos</a></li>
                  <li routerLinkActive="active">
                    <a href="https://plataforma.ita.ec/login/index.php" target="_blank">
                      Aula Virtual ITA
                    </a>
                  </li>
                </ul>
                <!-- <div class="search_button"><i class="fa fa-search" aria-hidden="true"></i></div>

                <div class="shopping_cart"><i class="fa fa-shopping-cart" aria-hidden="true"></i>
                </div> -->
                <div class="hamburger menu_mm">
                  <i class="fa fa-bars menu_mm" aria-hidden="true"></i>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="header_search_container">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="header_search_content d-flex flex-row align-items-center justify-content-end">
              <form action="#" class="header_search_form">
                <input type="search" class="search_input" placeholder="Buscar..." required="required">
                <button class="header_search_button d-flex flex-column align-items-center justify-content-center">
                  <i class="fa fa-search" aria-hidden="true"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <div class="menu d-flex flex-column align-items-end justify-content-start text-right menu_mm trans_400">
    <div class="menu_close_container">
      <div class="menu_close">
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="search">
      <form action="#" class="header_search_form menu_mm">
        <input type="search" class="search_input menu_mm" placeholder="Buscar..." required="required">
        <button class="header_search_button d-flex flex-column align-items-center justify-content-center menu_mm">
          <i class="fa fa-search menu_mm" aria-hidden="true"></i>
        </button>
      </form>
    </div>
    <nav class="menu_nav">
      <ul class="menu_mm">
        <li class="menu_mm"><a routerLink="/home">Inicio</a></li>
        <li class="menu_mm"><a routerLink="/about">Nosotros</a></li>
        <li class="menu_mm"><a routerLink="/courses">Carreras</a></li>
        <li class="menu_mm"><a routerLink="/contact">Contactos</a></li>
      </ul>
    </nav>
  </div>

  <router-outlet></router-outlet>

  <footer class="footer">
    <div class="footer_background"></div>
    <div class="container">
      <div class="row footer_row">
        <div class="col">
          <div class="footer_content">
            <div class="row">
              <div class="col-lg-3 footer_col">

                <div class="footer_section footer_about">
                  <div class="footer_logo_container">
                    <a href="#">
                      <div class="footer_logo_text">ITA</div>
                    </a>
                  </div>
                  <div class="footer_about_text">
                    <p>LA MEJOR OPCIÓN PARA TU FUTURO.</p>
                  </div>
                  <div class="footer_social">
                    <ul>
                      <li><a href="https://www.facebook.com/Instituto-Superior-Tecnol%C3%B3gico-Andino-103528581702036"
                          target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                      </li>
                      <!--                       <li><a href="#"><i class="fa fa-google-plus" aria-hidden="true"></i></a>
                      </li>
                      <li><a href="#"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                      </li>
                      <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                      </li> -->
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 footer_col">

                <div class="footer_section footer_contact">
                  <div class="footer_title">Contacta con nosotros</div>
                  <div class="footer_contact_info">
                    <ul>
                      <li>Email: info.istandino@gmail.com
                      </li>
                      <li>Teléfono: (02) 231 6236</li>
                      <li>Móvil: 099 504 5165</li>
                      <li>MACHAHI: JOSÉ MEJÍA E-190 Y ANTONIO JOSÉ DE SUCRE</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 footer_col">

                <div class="footer_section footer_links">
                  <div class="footer_title">Menú</div>
                  <div class="footer_links_container">
                    <ul>
                      <li><a routerLink="/home">Inicio</a></li>
                      <li><a routerLink="/about">Nosotros</a></li>
                      <li><a routerLink="/courses">Carreras</a></li>
                      <li><a routerLink="/contact">Contactos</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <!--               <div class="col-lg-3 footer_col clearfix">

                <div class="footer_section footer_mobile">
                  <div class="footer_title">Aplicación Móvil</div>
                  <div class="footer_mobile_content">
                    <div class="footer_image"><a href="#"><img src="assets/images/mobile_1.png" alt=""></a></div>
                    <div class="footer_image"><a href="#"><img src="assets/images/mobile_2.png" alt=""></a></div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="row copyright_row">
        <div class="col">
          <div class="copyright d-flex flex-lg-row flex-column align-items-center justify-content-start">
            <div class="cr_text">
              Copyright &copy; 2021
              by <a href="https://corebitsdev.com/" target="_blank">Corebits</a>
            </div>
            <div class="ml-lg-auto cr_links">
              <ul class="cr_list">
                <!-- <li><a href="#">Privacy Policy</a></li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</div>
