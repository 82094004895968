import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContainerAdminComponent } from './components/container-admin/container-admin.component';
import { ContainerAppComponent } from './components/container-app/container-app.component';
import { MessagesComponent } from './components/messages/messages.component';
import { EditNewComponent } from './components/news/edit-new/edit-new.component';
import { NewNewComponent } from './components/news/new-new/new-new.component';
import { NewsComponent } from './components/news/news.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { CourseComponent } from './components/pages/course/course.component';
import { CoursesComponent } from './components/pages/courses/courses.component';
import { HomeComponent } from './components/pages/home/home.component';
import { LoginComponent } from './components/pages/login/login.component';
import { CheckLoginGuard } from './shared/guards/check-login.guard';

const routes: Routes = [
  {
    path: '',
    component: ContainerAppComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: 'about',
        component: AboutComponent,
      },
      {
        path: 'courses',
        component: CoursesComponent,
      },
      {
        path: 'course/:id',
        component: CourseComponent,
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'contact',
        component: ContactComponent,
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'admin',
    component: ContainerAdminComponent,
    canActivate: [CheckLoginGuard],
    children: [
      {
        path: 'messages',
        component: MessagesComponent,
      },
      {
        path: 'news',
        component: NewsComponent,
      },
      {
        path: 'news/new',
        component: NewNewComponent,
      },
      {
        path: 'news/edit/:id',
        component: EditNewComponent,
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'home',
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
