import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedServiceService } from 'src/app/services/sharedService/shared-service.service';

@Component({
  selector: 'app-container-app',
  templateUrl: './container-app.component.html',
  styleUrls: ['./container-app.component.scss'],
})
export class ContainerAppComponent implements OnInit {

  public isLogin = false;

  constructor(private router: Router, private ss: SharedServiceService) {}

  ngOnInit(): void {
    this.isLogin = this.ss.valSesion();
  }
}
