import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedServiceService } from 'src/app/services/sharedService/shared-service.service';
import { StorageService } from 'src/app/services/storageService/storage.service';
import { WsItaService } from 'src/app/services/wsIta/ws-ita.service';
import { FileItem } from 'src/app/shared/models/file-item';
import Swal from 'sweetalert2';

import { finalize } from 'rxjs/operators';
@Component({
  selector: 'app-edit-new',
  templateUrl: './edit-new.component.html',
  styleUrls: ['./edit-new.component.scss'],
})
export class EditNewComponent implements OnInit {
  public newsForm: any;
  public currentNews: any;

  isLoading = true;

  files: FileItem[] = [];
  isOverDrop = false;

  isSaving = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ws: WsItaService,
    private readonly storageService: StorageService,
    private sharedService: SharedServiceService
  ) {}

  async ngOnInit(): Promise<void> {
    let id = this.route.snapshot.params.id;

    const datos = await new Promise<any>((resolve) =>
      this.ws.getNew(id).subscribe((translated) => {
        resolve(translated);
      })
    );

    if (datos.success) {
      this.currentNews = datos.res[0];
      this.isLoading = false;
    }

    this.newsForm = new FormGroup({
      title: new FormControl(this.currentNews.title, [Validators.required]),
      text: new FormControl(this.currentNews.text, [Validators.required]),
    });
  }

  async onEdit() {
    this.isSaving = true;
    let data = this.newsForm.value;
    if (this.newsForm.valid) {
      if (this.files.length > 0) {
        const task = this.storageService.uploadImage(this.files[0]);

        task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              this.files[0].downloadURL =
                this.sharedService.fileRef[0].getDownloadURL();
              this.files[0].uploading = false;
              this.saveImg(data);
            })
          )
          .subscribe();
      } else {
        this.save(data);
      }
    } else {
      Swal.fire('Cumpla con todos los campos', '', 'warning');
      this.isSaving = false;
    }
  }

  async save(data) {
    data['id'] = this.currentNews.id;
    data['date'] = this.currentNews.date;
    data['img'] = this.currentNews.img;

    const datos = await new Promise<any>((resolve) =>
      this.ws.putNews(data).subscribe((translated) => {
        resolve(translated);
      })
    );
    if (datos.success) {
      this.router.navigate(['admin/news']);
    } else {
      this.isSaving = false;
    }
  }

  async saveImg(data) {
    this.files[0].downloadURL.subscribe(async (url) => {
      data.img = url;
      data['id'] = this.currentNews.id;
      data['date'] = this.currentNews.date;

      const datos = await new Promise<any>((resolve) =>
        this.ws.putNews(data).subscribe((translated) => {
          resolve(translated);
        })
      );
      if (datos.success) {
        this.storageService.delete(this.currentNews.img);
        this.router.navigate(['admin/news']);
      } else {
        this.isSaving = false;
      }
    });
  }

  getErrorMessage(field: string): string {
    let message = '';
    if (this.newsForm.get(field).errors.required) {
      message = 'Debes ingresar un valor';
    } else if (this.newsForm.get(field).hasError('email')) {
      message = 'No es un email valido';
    } else if (this.newsForm.get(field).hasError('minlength')) {
      message = 'Este campo debe tener más de 6 caracteres';
    }
    return message;
  }

  isValidField(field: string): boolean {
    return this.newsForm.get(field).touched && !this.newsForm.get(field).valid;
  }
}
