<div class="home">
  <div class="breadcrumbs_container">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="breadcrumbs">
            <ul>
              <li><a routerLink="/">ITA</a></li>
              <li>Carreras</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="courses">
  <div class="container">
    <div class="row">

      <div class="col-lg-12">
        <div class="courses_search_container">
          <form action="#" id="courses_search_form"
            class="courses_search_form d-flex flex-row align-items-center justify-content-start">
            <h3>Todas las carreras</h3>
<!--             <input type="search" class="courses_search_input" placeholder="Buscar Carrera" required="required">
            <button action="https://preview.colorlib.com/theme/unicat/submit"
              class="courses_search_button ml-auto">Buscar</button> -->
          </form>
        </div>
        <div class="courses_container">
          <div class="row courses_row">

            <div class="col-lg-6 course_col" *ngFor="let item of courses">
              <div class="course">
                <div class="course_image"><img src="{{ item.image }}" alt="" style="width: 100%; max-height: 300px; object-fit: cover;"></div>
                <div class="course_body">
                  <h3 class="course_title"><a routerLink="/course/{{ item.id }}">{{ item.name }}</a></h3>
                  <div class="course_teacher"></div>
                  <div class="course_text">
                    <p>{{ item.time }}</p>
                  </div>
                </div>
                <div class="course_footer">
                  <div class="course_footer_content d-flex flex-row align-items-center justify-content-start">
                    <div class="course_info">
                      <i class="fa fa-graduation-cap" aria-hidden="true"></i>
                      <span>{{ item.student }} Estudiantes</span>
                    </div>
                    <div class="course_info">
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <span>{{ item.ratings }} Calificaciones</span>
                    </div>
                    <div class="course_price ml-auto"></div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>
