import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContainerAppComponent } from './components/container-app/container-app.component';
import { ContainerAdminComponent } from './components/container-admin/container-admin.component';
import { HomeComponent } from './components/pages/home/home.component';
import { AboutComponent } from './components/pages/about/about.component';
import { CoursesComponent } from './components/pages/courses/courses.component';
import { CourseComponent } from './components/pages/course/course.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { LoginComponent } from './components/pages/login/login.component';
import { MessagesComponent } from './components/messages/messages.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { NewsComponent } from './components/news/news.component';
import { WsItaService } from './services/wsIta/ws-ita.service';
import { HttpClientModule } from '@angular/common/http';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { ReactiveFormsModule } from '@angular/forms';
import { EditNewComponent } from './components/news/edit-new/edit-new.component';
import { NewNewComponent } from './components/news/new-new/new-new.component';
import { NewMessageComponent } from './components/messages/new-message/new-message.component';
import { EditMessageComponent } from './components/messages/edit-message/edit-message.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule, BUCKET } from '@angular/fire/storage';
import { environment } from '../environments/environment';
import { NgFilesDirective } from './shared/directives/ng-files.directive';

@NgModule({
  declarations: [
    AppComponent,
    ContainerAppComponent,
    ContainerAdminComponent,
    HomeComponent,
    AboutComponent,
    CoursesComponent,
    CourseComponent,
    ContactComponent,
    LoginComponent,
    MessagesComponent,
    NewsComponent,
    SpinnerComponent,
    EditNewComponent,
    NewNewComponent,
    NewMessageComponent,
    EditMessageComponent,
    NgFilesDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireStorageModule
  ],
  providers: [
    WsItaService,
    { provide: BUCKET, useValue: 'gs://sistemaita.appspot.com' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
