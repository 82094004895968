<app-spinner *ngIf="isLoading; else content"></app-spinner>

<ng-template #content>
  <div class="row">
    <div class="col-sm-12 col-md-12 mx-auto">
      <div class="card">
        <div class="card-body">
          <h3 class="text-center">Nueva Noticia</h3>

          <form [formGroup]="newsForm" (ngSubmit)="onCreate()">

            <mat-form-field class="full-width-input">
              <input type="text" formControlName="title" matInput placeholder="Título" required />
              <mat-error *ngIf="isValidField('title')">
                {{ getErrorMessage("title") }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width-input">
              <textarea type="email" formControlName="text" rows="5" matInput placeholder="Texto" required></textarea>
              <mat-error *ngIf="isValidField('text')">
                {{ getErrorMessage("text") }}
              </mat-error>
            </mat-form-field>

            <div class="row files">
              <div class="col-md-4 mt-5">
                <div class="card">
                  <div class="card-header">
                    <span>
                      Imagen
                    </span>
                  </div>
                  <div class="card-body">
                    <div class="drop-zone" appNgFiles [files]="files" (mouseOver)="isOverDrop = $event" [ngClass]="{'file-over':
                    isOverDrop}">
                      <h4>Arrastre su imagen</h4>
                      <img src="../../../../assets/image/cloud.png" class="img-fluid" alt="drop-image">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-8 mt-5">
                <div class="card">
                  <div class="card-header">
                    <span>
                      Subir foto
                    </span>
                  </div>
                  <div class="card-body">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Nombre</th>
                          <th>Tamaño</th>
                          <th>Progreso</th>
                          <th>URL</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let file of files">
                          <td>{{file.name}}</td>
                          <td>{{file.file.size / 1024 / 1024 | number: "1.2-2"}} MB</td>
                          <td *ngIf="file.progress | async as progress">
                            <div class="progress">
                              <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': progress + '%' }">
                                {{ progress | number: "1.0-0" }} %
                              </div>
                            </div>
                          </td>
                          <td *ngIf="file.downloadURL | async as downloadURL">
                            <a [href]="downloadURL" target="_blank">
                              Descargar Imagen
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="container-buttons">
                      <button class="btn btn-danger" (click)="files = []">Borrar</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button type="submit" class="btn btn-primary btn-block mt-3" [disabled]="files.length === 0 || isSaving">
              Guardar
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>
