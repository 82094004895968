import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storageService/storage.service';
import { WsItaService } from 'src/app/services/wsIta/ws-ita.service';
import { FileItem } from 'src/app/shared/models/file-item';
import Swal from 'sweetalert2';

import { finalize } from 'rxjs/operators';
import { SharedServiceService } from 'src/app/services/sharedService/shared-service.service';
@Component({
  selector: 'app-new-new',
  templateUrl: './new-new.component.html',
  styleUrls: ['./new-new.component.scss'],
  providers: [StorageService],
})
export class NewNewComponent implements OnInit {
  public newsForm: any;

  isLoading = false;

  files: FileItem[] = [];
  isOverDrop = false;

  isSaving= false;

  constructor(
    private router: Router,
    private ws: WsItaService,
    private readonly storageService: StorageService,
    private sharedService: SharedServiceService
  ) {}

  ngOnInit(): void {
    this.newsForm = new FormGroup({
      title: new FormControl('', [Validators.required]),
      text: new FormControl('', [Validators.required]),
    });
  }

  onUpload(event) {
    this.storageService.uploadImages(this.files);
  }

  async onCreate() {
    this.isSaving = true;
    let data = this.newsForm.value;
    if (this.newsForm.valid) {
      const task = this.storageService.uploadImage(this.files[0]);

      task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            this.files[0].downloadURL =
              this.sharedService.fileRef[0].getDownloadURL();
            this.files[0].uploading = false;
            this.save(data);
          })
        )
        .subscribe();
    } else {
      Swal.fire('Cumpla con todos los campos', '', 'warning');
      this.isSaving = false;
    }
  }

  async save(data) {
    this.files[0].downloadURL.subscribe(async (url) => {
      data.img = url;
      data['date'] = formatDate(new Date(), 'yyyy/MM/dd', 'en');
      const datos = await new Promise<any>((resolve) =>
        this.ws.postNews(data).subscribe((translated) => {
          resolve(translated);
        })
      );
      if (datos.success) {
        this.router.navigate(['admin/news']);
      } else {
        this.isSaving = false;
      }
    });
  }

  getErrorMessage(field: string): string {
    let message = '';
    if (this.newsForm.get(field).errors.required) {
      message = 'Debes ingresar un valor';
    } else if (this.newsForm.get(field).hasError('email')) {
      message = 'No es un email valido';
    } else if (this.newsForm.get(field).hasError('minlength')) {
      message = 'Este campo debe tener más de 6 caracteres';
    }
    return message;
  }

  isValidField(field: string): boolean {
    return this.newsForm.get(field).touched && !this.newsForm.get(field).valid;
  }
}
