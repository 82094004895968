import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedServiceService } from 'src/app/services/sharedService/shared-service.service';
import { WsItaService } from 'src/app/services/wsIta/ws-ita.service';
import Swal from 'sweetalert2';
declare const initHomeSlider: any;
declare const initMilestones: any;


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit {
  public courses: any;
  public messageForm: any;

  news = []

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ss: SharedServiceService,
    private ws: WsItaService
  ) {}

  async ngOnInit(): Promise<void> {
    this.courses = this.ss.courses;
    this.messageForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [Validators.required]),
      text: new FormControl('', [Validators.required]),
    });

    const datos = await new Promise<any>((resolve) =>
      this.ws.getNews().subscribe((translated) => {
        resolve(translated);
      })
    );

    if (datos.res) {
      this.news = datos.res;
    }
  }

  async sendMessage() {
    if (this.messageForm.valid) {
      let data = this.messageForm.value;
      data["state"] = true;
      const datos = await new Promise<any>((resolve) =>
        this.ws.postMessage(data).subscribe((translated) => {
          resolve(translated);
        })
      );

      if (datos.success) {
        this.messageForm = new FormGroup({
          name: new FormControl('', [Validators.required]),
          email: new FormControl('', [Validators.required, Validators.email]),
          phone: new FormControl('', [Validators.required]),
          text: new FormControl('', [Validators.required]),
        });
        Swal.fire('Mensaje Enviado', "", 'success');
      } else {
        Swal.fire('Error', "No se pudo enviar el mensaje.", 'warning');
      }
    }else{
      Swal.fire('Cumpla con todos los campos', "No se pudo enviar el mensaje.", 'warning');
    }
  }

  ngAfterViewInit(): void {
    initHomeSlider();
    initMilestones();
  }
}
