import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { WsItaService } from 'src/app/services/wsIta/ws-ita.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  public messageForm: any;

  constructor(private router: Router, private ws: WsItaService) {}

  ngOnInit(): void {
    this.messageForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [Validators.required]),
      text: new FormControl('', [Validators.required]),
    });
  }

  async sendMessage() {
    if (this.messageForm.valid) {
      let data = this.messageForm.value;
      data['state'] = true;
      const datos = await new Promise<any>((resolve) =>
        this.ws.postMessage(data).subscribe((translated) => {
          resolve(translated);
        })
      );

      if (datos.success) {
        this.messageForm = new FormGroup({
          name: new FormControl('', [Validators.required]),
          email: new FormControl('', [Validators.required, Validators.email]),
          phone: new FormControl('', [Validators.required]),
          text: new FormControl('', [Validators.required]),
        });
        Swal.fire('Mensaje Enviado', '', 'success');
      } else {
        Swal.fire('Error', 'No se pudo enviar el mensaje.', 'warning');
      }
    } else {
      Swal.fire(
        'Cumpla con todos los campos',
        'No se pudo enviar el mensaje.',
        'warning'
      );
    }
  }

  getErrorMessage(field: string): string {
    let message = '';
    if (this.messageForm.get(field).errors.required) {
      message = 'Ingresar un valor';
    } else if (this.messageForm.get(field).hasError('email')) {
      message = 'No es un email valido';
    } else if (this.messageForm.get(field).hasError('minlength')) {
      message = 'Ingrese mínimo de 8 caracteres';
    }
    return message;
  }

  isValidField(field: string): boolean {
    return (
      (this.messageForm.get(field).touched || this.messageForm.submitted) &&
      !this.messageForm.get(field).valid
    );
  }
}
