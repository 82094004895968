<mat-toolbar class="mat-elevation-z8">
  <button mat-icon-button *ngIf="sidenav.mode === 'over'" (click)="sidenav.toggle()">
    <mat-icon *ngIf="!sidenav.opened">
      menu
    </mat-icon>
    <mat-icon *ngIf="sidenav.opened">
      close
    </mat-icon>
  </button>
  <span>
    <a routerLink="/">
      Instituto Superior Tecnológico Andino
    </a>
  </span>
  <span class="example-spacer"></span>
  <button mat-button (click)="closeSesion()">
    Cerrar sesión
  </button>
</mat-toolbar>

<mat-sidenav-container>

  <mat-sidenav #sidenav="matSidenav" class="mat-elevation-z8">

    <img class="avatar mat-elevation-z8" src="assets/image/avatar.png" />

    <h4 class="name">Admin</h4>
    <p class="designation">Administrador</p>

    <mat-divider></mat-divider>

    <button routerLink="/admin/messages" mat-button class="menu-button">
      <mat-icon>email</mat-icon>
      <span>Mensajes</span>
    </button>
    <button routerLink="/admin/news" mat-button class="menu-button">
      <mat-icon>article</mat-icon>
      <span>Noticias</span>
    </button>

    <mat-divider></mat-divider>

    <button mat-button class="menu-button">
      <mat-icon>help</mat-icon>
      <span>Ayuda</span>
    </button>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="content mat-elevation-z8">
      <div class="container">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>

</mat-sidenav-container>
