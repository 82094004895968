import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedServiceService } from 'src/app/services/sharedService/shared-service.service';
declare const initTabs: any;

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss'],
})
export class CourseComponent implements OnInit {
  public course: any;
  public courses: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ss: SharedServiceService
  ) {}

  ngOnInit(): void {
    let id = this.route.snapshot.params.id;
    this.courses = this.ss.courses;
    this.course = this.ss.courses[id - 1];
  }

  ngAfterViewInit(): void {
    initTabs();
  }

  change(id: any) {
    this.course = this.course = this.ss.courses[id - 1];
  }
}
