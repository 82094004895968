import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { SharedServiceService } from 'src/app/services/sharedService/shared-service.service';

@Component({
  selector: 'app-container-admin',
  templateUrl: './container-admin.component.html',
  styleUrls: ['./container-admin.component.scss'],
})
export class ContainerAdminComponent implements OnInit {
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;

  public isLogin = false;

  constructor(
    private observer: BreakpointObserver,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private ss: SharedServiceService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.observer.observe(['(max-width: 800px)']).subscribe((res) => {
      if (res.matches) {
        this.sidenav.mode = 'over';
        this.sidenav.close();
      } else {
        this.sidenav.mode = 'side';
        this.sidenav.open();
      }
    });
    this.cdRef.detectChanges();
  }

  closeSesion() {
    localStorage.setItem('currentUser', '');
    this.router.navigate(['home']);
  }
}
